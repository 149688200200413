import { FunctionComponent, useCallback, useState } from "react";
import {
  Modal,
  ActionIcon,
  Select,
  Button,
  Group,
  Switch,
  TextInput,
} from "@mantine/core";
import { AiFillSetting } from "react-icons/ai";
import store, { actions, useTypedSelector } from "@/stores";
import { useNetwork } from "wagmi";
import { enableFeature, disableFeature } from "@/services/features";
import { useDynamicContext } from "@dynamic-labs/sdk-react-core";
import { dynamicAdminVerify } from "@/services/user";
import { getUserInfo } from "@/services/user";
import { toast } from "react-toastify";

const CustomUserLoginModal: FunctionComponent = () => {
  const customUserLoginModal = useTypedSelector((state) => {
    return state.ui.customUserLoginModal;
  });

  const { chain } = useNetwork();

  const opened = !!customUserLoginModal;
  const [address, setAddress] = useState("");

  const { user: dynamicUser, primaryWallet, authToken } = useDynamicContext();

  const onLogin = useCallback(async () => {
    if (!dynamicUser || !primaryWallet || !authToken) {
      return;
    }
    await enableFeature("vConsole");
    await store.dispatch(
      actions.devtool.setConfig({
        ...store.getState().devtool.config,
        backend: "https://api.karatdao.com/read/action",
        customAddress: address,
      })
    );
    const session = await dynamicAdminVerify({
      walletAddr: primaryWallet.address,
      jwt: authToken,
      useridx: dynamicUser.userId as string,
      address,
    });
    await store.dispatch(actions.account.setSession(session));
    await getUserInfo({
      userId: session.user_id,
    }).then((user) => {
      store.dispatch(actions.account.setUser(user));
    });
    toast.success("Login success");
    store.dispatch(actions.ui.setCustomUserLoginModal(null));
  }, [address, authToken, primaryWallet, dynamicUser]);

  return (
    <Modal
      opened={opened}
      centered
      onClose={() => {
        store.dispatch(actions.ui.setCustomUserLoginModal(null));
      }}
      title="Custom User Login"
      zIndex={999}
    >
      <div>
        <TextInput
          label="Custom wallet address"
          placeholder="0x0f9..13"
          defaultValue={address}
          onChange={(e) => {
            setAddress(e.target.value);
          }}
        />
        <div className="mt-4 flex justify-center">
          <Button color="orange" onClick={onLogin}>
            Login
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default CustomUserLoginModal;
