import "@/styles/globals.css";
import type { AppProps } from "next/app";
import { MantineProvider, createTheme } from "@mantine/core";
import store, { actions } from "@/stores";
import { Provider } from "react-redux";
import { DynamicContextProvider } from "@dynamic-labs/sdk-react-core";
import { DynamicWagmiConnector } from "@dynamic-labs/wagmi-connector";
import { contextConfig } from "@/services/dynamic";
import AppContainer from "@/components/common/App";
import { ToastContainer } from "react-toastify";
import { CheckIcon } from "@/components/common/icons";
import "@sendbird/uikit-react/dist/index.css";
import "@mantine/core/styles.css";
import "react-photo-view/dist/react-photo-view.css";
import "react-toastify/dist/ReactToastify.css";
import "@mantine/carousel/styles.css";
import ErrorBoundary from "@/components/common/ErrorBoundary";
import { useEffect } from "react";

const theme = createTheme({});

export default function App({ Component, pageProps }: AppProps) {
  useEffect(() => {
    store.dispatch(actions.app.initApp());
  }, []);

  return (
    <DynamicContextProvider {...contextConfig}>
      <DynamicWagmiConnector>
        <Provider store={store}>
          <MantineProvider theme={theme}>
            <ToastContainer
              position="top-center"
              hideProgressBar
              closeButton={false}
              icon={(prop) => {
                if (prop.type === "success") {
                  return <CheckIcon></CheckIcon>;
                }
                return null;
              }}
              autoClose={2500}
            ></ToastContainer>
            <ErrorBoundary>
              <AppContainer>
                <Component {...pageProps} />
              </AppContainer>
            </ErrorBoundary>
          </MantineProvider>
        </Provider>
      </DynamicWagmiConnector>
    </DynamicContextProvider>
  );
}
