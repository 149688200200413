import { useEffect, useState } from "react";
import { UAParser } from "ua-parser-js";
import { useTypedSelector } from "@/stores";

export function useDevice() {
  const [isMobile, setMobile] = useState(true);
  const [isStandAlone, setStandAlone] = useState(true);
  const [isMetamaskBrowser, setMetamaskBrowser] = useState(false);
  const [hasInjectWallet, setHasInjectWallet] = useState(false);
  const [isAndroid, setAndroid] = useState(false);

  useEffect(() => {
    const ua = UAParser(navigator.userAgent);
    setAndroid(ua.os.name === "Android");
    setMobile(ua.device.type === "mobile" || ua.device.type === "tablet");
    try {
      const mqStandAlone = "(display-mode: standalone)";
      setStandAlone(
        (navigator as any).standalone || window.matchMedia(mqStandAlone).matches
      );
    } catch (e) {
      setStandAlone(false);
    }
    setHasInjectWallet(typeof window.ethereum !== "undefined");
    if (typeof window.ethereum !== "undefined") {
      // @ts-ignore
      setMetamaskBrowser(window.ethereum.isMetaMask);
    }
  }, []);

  const devMode = useTypedSelector((state) => {
    return state.ui.devMode;
  });

  const iosWebPushDisable = isMobile && !isAndroid && !isStandAlone;

  return {
    isMobile,
    isStandAlone: isStandAlone || devMode,
    isMetamaskBrowser,
    hasInjectWallet,
    isAndroid,
    iosWebPushDisable,
  };
}
