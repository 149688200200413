import { FunctionComponent, PropsWithChildren, useEffect } from "react";
import AppCover from "@/components/common/AppCover";
import { useRouter } from "next/router";
import store, { useTypedSelector, actions } from "@/stores";
import { getRouteByPath } from "@/services/router";
import events from "@/services/events";
import { handleActionSheet } from "@/services/app";
import TopActionSheet from "@/components/common/TopActionSheet";
import useAccount from "@/hooks/useAccount";
import SendbirdProvider from "@sendbird/uikit-react/SendbirdProvider";
import { needLogin } from "@/services/router";
import useSession from "@/hooks/useSession";
import { LoginState } from "@/types/store";
import Head from "next/head";
import mixpanel from "mixpanel-browser";
import AppTabs from "@/components/common/AppTabs";
import { useDevice } from "@/hooks/useDevice";
import Image from "next/image";
import QRCode from "qrcode.react";
import ContractStatusModal from "@/components/common/ContractStatusModal";
import NetworkAddModal from "./NetworkAddModal";
import UserSetupDialog from "@/components/account/UserSetupDialog";
import PageView from "./PageView";
import { Share1Icon, AddIcon } from "./icons";
import TouchableHighlight from "./TouchableHighlight";
import { FiMoreVertical } from "react-icons/fi";
import { MdInstallMobile } from "react-icons/md";
import LoginDialog from "./LoginDialog";
import AppWelcomeDialog from "../app/WelcomeDialog";
import UserChannelList from "../chat/UserChannelList";
import { useSetupOneSignal } from "@/hooks/useSetupOneSignal";
import stores from "store2";
import DevtoolMenu from "@/components/devtool/Menu";
import CustomUserLoginModal from "@/components/devtool/CustomUserLoginModal";

let vconsole: any = null;

const App: FunctionComponent<PropsWithChildren> = function ({ children }) {
  const router = useRouter();
  const { isMobile, isStandAlone, isMetamaskBrowser, isAndroid } = useDevice();

  useSession();

  useSetupOneSignal();

  const isMaintenance = process.env.NEXT_PUBLIC_MAINTENANCE_MODE === "true";

  const { session, loginState } = useTypedSelector((state) => {
    return state.account;
  });

  const enableVConsole = useTypedSelector((state) => {
    return state.features.list.vConsole;
  });

  const { user, isLogin } = useAccount();

  const needWaitLogin = needLogin(router.pathname);

  useEffect(() => {
    // @ts-ignore
    const routerConfig = getRouteByPath(router.pathname);

    const theme = routerConfig?.theme || "#F87450";
    document
      .querySelector('meta[name="theme-color"]')
      ?.setAttribute("content", theme);
  }, [router.pathname]);

  const { devMode, enableBrowserVisit } = useTypedSelector((state) => {
    return state.ui;
  });

  useEffect(() => {
    if (enableVConsole && !vconsole) {
      setTimeout(() => {
        // @ts-ignore
        vconsole = new window.VConsole({ theme: "dark" });
      }, 1000);
    }
    if (!enableVConsole && vconsole) {
      try {
        vconsole.destroy();
        vconsole = null;
      } catch (e: any) {}
    }
  }, [enableVConsole]);

  useEffect(() => {
    if (router.query.mode === "B6tklKUB9dHtCDG") {
      store.dispatch(actions.ui.setDevMode(true));
    }
    if (router.query.mode === "uGx0Bo9iUV8XEWf") {
      store.dispatch(actions.ui.setDevMode(false));
    }
    if (router.query.space) {
      const planet = router.query.space;
      router.replace({
        pathname: "/",
      });
      router.push(`/space/${planet}`);
    }
    store.dispatch(actions.ui.showPageView(null));
  }, [router]);

  useEffect(() => {
    if (process.env.NEXT_PUBLIC_APP_ENV === "production") {
      mixpanel.init("828ebac349f5d9b2a85ba58acec75a9e");
    } else {
      mixpanel.init("53984ecda43c69ce4efc1297ddd60821", {
        debug: true,
      });
    }
    console.log("mixpanel inited");
    events.on("clickActionSheet", handleActionSheet);
    store.dispatch(actions.features.setFeatures(stores.get("features", {})));
    return () => {
      events.off("clickActionSheet", handleActionSheet);
    };
  }, []);

  // if (!isMobile) {
  //   return (
  //     <div>
  //       <Head>
  //         <title>Karat.club</title>
  //         <meta
  //           name="viewport"
  //           content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
  //         />
  //       </Head>
  //       <div className="w-screen h-screen flex flex-col items-center justify-center bg-bg bg-cover">
  //         <Image
  //           src="/assets/logo.png"
  //           width={120}
  //           height={120}
  //           alt="logo"
  //           className="rounded-[20px]"
  //         ></Image>
  //         <div className="mt-[30px] font-extrabold text-[60px]">Karat.Club</div>
  //         <div className="mt-6 text-center">
  //           <div>
  //             Currently, opening the link through a web browser is not
  //             supported.
  //           </div>
  //           <div>
  //             Please use your mobile brower to acess this link or scan the QR
  //             code below to use our App.
  //           </div>
  //         </div>
  //         <div className="py-[17px] px-[13px] bg-white mt-16 rounded-[25px]">
  //           <QRCode
  //             renderAs="svg"
  //             size={152}
  //             value={"https://www.karat.club"}
  //           />
  //         </div>
  //       </div>
  //     </div>
  //   );
  // }

  if (
    isMobile &&
    !isStandAlone &&
    !isMetamaskBrowser &&
    !enableBrowserVisit &&
    needWaitLogin &&
    !isLogin
  ) {
    return (
      <div>
        <Head>
          <title>Karat.club</title>
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
          />
        </Head>
        <div className="w-screen flex flex-col items-center bg-dark1 px-5 pt-20">
          <Image
            src="/assets/add-homescreen.png"
            width={110}
            height={100}
            alt="logo"
          ></Image>
          <div className="mt-5 font-bold text-[20px] text-white">
            Add to Home Screen
          </div>
          <div className="mt-2 text-white text-center">
            To install the application, please open this link in your browser
            and then add the website to your home screen.
          </div>
          <div className="w-full mt-7 bg-dark2 rounded-[10px] py-[19px] pl-5 pr-9 flex items-center justify-between">
            <div className="text-white">1. Tap on</div>
            <div className="w-[30px] h-[30px] flex items-center justify-center bg-white rounded-[4px]">
              {isAndroid ? (
                <FiMoreVertical className="text-dark1"></FiMoreVertical>
              ) : (
                <Share1Icon></Share1Icon>
              )}
            </div>
          </div>
          <div className="w-full mt-5 bg-dark2 rounded-[10px] py-[19px] pl-5 pr-4">
            <div className="text-white">2. Select</div>
            <div className="w-fill mt-[10px] bg-gray1 rounded-lg py-3 px-6 flex items-center justify-between">
              <div className="text-white text-sm">
                {isAndroid ? "Install App" : "Add to Home Screen"}
              </div>
              {isAndroid ? (
                <MdInstallMobile className="text-white text-[22px]"></MdInstallMobile>
              ) : (
                <AddIcon></AddIcon>
              )}
            </div>
          </div>
          <div className="h-[0.5px] w-full bg-[#eeeeee] my-[30px]"></div>
          <div className="flex justify-center">
            <TouchableHighlight theme="text">
              <div
                className="font-bold text-primary"
                onClick={() => {
                  store.dispatch(actions.ui.setBrowserVisit(true));
                }}
              >
                or continue in your browser
              </div>
            </TouchableHighlight>
          </div>
          <div className="my-12 text-white text-2xl font-extrabold text-center">
            Karat.Club
          </div>
        </div>
        <DevtoolMenu></DevtoolMenu>
      </div>
    );
  }

  if (!needWaitLogin && !isMaintenance) {
    <div>
      <Head>
        <title>Karat.club</title>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
        />
      </Head>

      <div>{children}</div>
      <AppCover></AppCover>
      <TopActionSheet></TopActionSheet>
      <DevtoolMenu></DevtoolMenu>
    </div>;
  }

  return (
    <div>
      <Head>
        <title>Karat.club</title>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
        />
      </Head>

      {!isMaintenance && loginState === LoginState.Login && needWaitLogin && (
        <SendbirdProvider
          appId={process.env.NEXT_PUBLIC_SENDBIRD_APP_ID as string}
          userId={String(user?.userId)}
          accessToken={session?.sendbird_token}
        >
          <div>{children}</div>
          <UserChannelList></UserChannelList>
        </SendbirdProvider>
      )}

      {!isMaintenance && !needWaitLogin && <div>{children}</div>}
      <AppTabs></AppTabs>
      <AppCover></AppCover>
      <TopActionSheet></TopActionSheet>
      <ContractStatusModal></ContractStatusModal>
      <UserSetupDialog></UserSetupDialog>
      <PageView></PageView>
      <NetworkAddModal></NetworkAddModal>
      <LoginDialog></LoginDialog>
      <AppWelcomeDialog></AppWelcomeDialog>
      <DevtoolMenu></DevtoolMenu>
      <CustomUserLoginModal></CustomUserLoginModal>
    </div>
  );
};

export default App;
