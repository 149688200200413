import { FunctionComponent, PropsWithChildren } from "react";
import TouchableHighlight from "@/components/common/TouchableHighlight";

const IconButton: FunctionComponent<
  PropsWithChildren & {
    className?: string;
    onClick?: (e: any) => void;
    hoverStyle?: any;
  }
> = function ({
  children,
  className = "",
  hoverStyle = {},
  onClick = () => {},
}) {
  return (
    <TouchableHighlight
      display="flex"
      className={`w-11 h-11 rounded-full flex items-center justify-center bg-primary ${className}`}
      hoverStyle={{
        transform: "scale(0.95)",
        background: "#F87450",
        ...hoverStyle,
      }}
      onClick={onClick}
    >
      {children}
    </TouchableHighlight>
  );
};

export default IconButton;
