import type { Chain } from "wagmi";
export declare type ActionSheet = {
  label: string;
  id: string;
  meta?: any;
};

export declare type GroupInviteModalConfig = null | {};
export declare type LoginModalConfig = null | {};
export declare type JoinGroupModalConfig = null | {};
export declare type WelcomeModalConfig = null | {};
export declare type GroupDetailModalConfig = null | {
  id: string;
};
export declare type ActionSheetsModalConfig = null | {
  sheets: ActionSheet[];
  title: string;
};

export declare type ContractStatusModalConfig = null | {
  title: string;
  desc: string;
};

export enum StakingTab {
  "stake",
  "unStake",
}

export enum ExploreTab {
  amount = "amount",
  incr = "incr",
}

export declare type PageViewConfig = null | {
  url: string;
};

export declare type CustomUserLoginModalConfig = null | {};

export declare type AddNetworkConfig = null | {
  chain: Chain;
  opened: boolean;
};

export declare type UIState = {
  appInited: boolean;
  groupInviteModal: GroupInviteModalConfig;
  groupDetailModal: GroupDetailModalConfig;
  loginModal: LoginModalConfig;
  joinGroupModal: JoinGroupModalConfig;
  welcomeModal: WelcomeModalConfig;
  topActionSheets: ActionSheetsModalConfig;
  contractStatusModal: ContractStatusModalConfig;
  stakingTab: StakingTab;
  pageView: PageViewConfig;
  spacesTab: ExploreTab;
  devMode: boolean;
  addNetwork: AddNetworkConfig;
  enableBrowserVisit: boolean;
  hasMessageUnread: boolean;
  notificationEnable: boolean;
  customUserLoginModal: CustomUserLoginModalConfig;
};

export enum LoginState {
  Idle = 0,
  WallstConnected,
  DynamicAuthed,
  SessionInited,
  InitUser,
  Login,
}
