import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import store from "store2";
import { FeatureList } from "@/services/features";

const slice = createSlice({
  name: "features",
  initialState: {
    list: {
      vConsole: false,
    },
  } as {
    list: FeatureList;
  },
  reducers: {
    setFeatures(state, action: PayloadAction<Partial<FeatureList>>) {
      const features = {
        ...state.list,
        ...action.payload,
      };
      store.set("features", features);
      state.list = features;
    },
  },
});

export const actions = {
  ...slice.actions,
};

export default slice.reducer;
