import { FunctionComponent, useCallback, useEffect, useState } from "react";
import { Modal, Loader, Button } from "@mantine/core";
import { useTypedSelector } from "@/stores";
import { useDisclosure } from "@mantine/hooks";
import { AiOutlineClose } from "react-icons/ai";
import store, { actions } from "@/stores";
import { useDynamicContext } from "@dynamic-labs/sdk-react-core";
import Image from "next/image";
import { useDevice } from "@/hooks/useDevice";

const ContractStatusModal: FunctionComponent = function () {
  const [opened, { open, close }] = useDisclosure(false);
  const status = useTypedSelector((state) => {
    return state.ui.contractStatusModal;
  });
  const context = useDynamicContext();
  const { hasInjectWallet } = useDevice();

  const isEmbeddedWallet = context?.walletConnector?.isEmbeddedWallet;

  useEffect(() => {
    if (!!status) {
      open();
    } else {
      close();
    }
  }, [status, open, close]);

  const onClose = useCallback(() => {
    store.dispatch(actions.ui.updateContractStatusModal(null));
  }, []);

  return (
    <Modal
      opened={opened}
      withCloseButton={false}
      closeOnClickOutside={false}
      closeOnEscape={false}
      onClose={onClose}
      size={383}
      radius={40}
      zIndex={10000}
      classNames={{
        content: "!bg-dark1 !py-10 !px-6 relative",
        body: "!p-0",
      }}
    >
      <div className="flex flex-col items-center justify-center text-white">
        <div className="absolute right-6 top-6">
          <AiOutlineClose onClick={close}></AiOutlineClose>
        </div>

        <div className="font-bold text-[28px] relative flex justify-center w-full">
          <span>{status?.title}</span>
        </div>
        <div className="mt-4">
          <div>{status?.desc}</div>
        </div>
        <Image
          className="mt-4"
          alt="loader"
          src="/assets/spin.gif"
          width={50}
          height={50}
        ></Image>
        {!isEmbeddedWallet && !hasInjectWallet ? (
          <Button
            className="mt-6 !h-[60px] !bg-primary !text-dark1 !text-base !font-bold"
            radius="xl"
            fullWidth
            onClick={() => {
              window.open("https://metamask.app.link", "_self");
            }}
          >
            Redirect to wallet
          </Button>
        ) : null}
      </div>
    </Modal>
  );
};

export default ContractStatusModal;
