import React, { Component, ErrorInfo, ReactNode } from "react";
import { Button } from "@mantine/core";
import * as Sentry from "@sentry/nextjs";

interface Props {
  children?: ReactNode;
}

interface State {
  hasError: boolean;
  errorMsg: string;
}

class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
    errorMsg: "",
  };

  public static getDerivedStateFromError(err: Error): State {
    return { hasError: true, errorMsg: err.message };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    Sentry.captureEvent({
      message: "App uncaught error",
      extra: {
        errorMsg: error.message,
        name: error.name,
        cause: error.cause,
        stack: error.stack,
      },
    });
  }

  public render() {
    if (this.state.hasError) {
      return (
        <div className="w-screen h-screen flex flex-col items-center justify-center bg-dark1 px-5">
          <div className="font-bold mb-4 text-white">Unknown Error</div>
          <div className="text-white text-sm mb-10 text-center font-semibold break-all">
            {this.state.errorMsg}
          </div>
          <Button
            className="mt-6 !w-[200px] !h-[60px] !bg-primary !text-dark1 !text-base !font-bold"
            radius="xl"
            fullWidth
            onClick={() => {
              window.location.reload();
            }}
          >
            Reload page
          </Button>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
