import { FunctionComponent, useCallback, useEffect, useState } from "react";
import { Modal, Loader, Button } from "@mantine/core";
import { useTypedSelector } from "@/stores";
import { useDisclosure } from "@mantine/hooks";
import { AiOutlineClose } from "react-icons/ai";
import store, { actions } from "@/stores";
import TouchableHighlight from "./TouchableHighlight";
import useAccount from "@/hooks/useAccount";

const NetworkAddModal: FunctionComponent = function () {
  const [opened, { open, close }] = useDisclosure(false);
  const networkConfig = useTypedSelector((state) => {
    return state.ui.addNetwork;
  });
  const { logout } = useAccount();

  useEffect(() => {
    if (!!networkConfig) {
      open();
    } else {
      close();
    }
  }, [networkConfig, open, close]);

  const onClose = useCallback(() => {
    store.dispatch(actions.ui.addNetwork(null));
  }, []);

  const networkName = networkConfig?.chain?.name;

  return (
    <Modal
      opened={opened}
      withCloseButton={false}
      closeOnClickOutside={false}
      closeOnEscape={false}
      onClose={onClose}
      size={383}
      radius={40}
      zIndex={10000}
      classNames={{
        content: "!bg-dark1 !py-10 !px-6 relative",
        body: "!p-0",
      }}
    >
      <div className="flex flex-col items-center justify-center text-white">
        {networkConfig?.opened ? (
          <>
            <div className="font-bold text-[28px] relative flex justify-center w-full">
              <span>Success</span>
            </div>
            <div className="mt-4">
              <div>
                You must log out and then log back into the app for the changes
                to take effect.
              </div>
            </div>

            <Button
              className="mt-6 !h-[60px] !bg-primary !text-dark1 !text-base !font-bold"
              radius="xl"
              fullWidth
              onClick={() => {
                onClose();
                logout();
              }}
            >
              Log out
            </Button>
          </>
        ) : (
          <>
            <div className="font-bold text-[28px] relative flex justify-center w-full">
              <span>Add {networkName} network</span>
            </div>
            <div className="mt-4">
              <div>
                Follow the instruction to add {networkName} network in your
                wallet app.
              </div>
            </div>

            <Button
              className="mt-6 !h-[60px] !bg-primary !text-dark1 !text-base !font-bold"
              radius="xl"
              fullWidth
              onClick={() => {
                store.dispatch(
                  actions.ui.addNetwork({
                    opened: true,
                    chain: networkConfig!.chain,
                  })
                );
                window.open(
                  `https://metamask.app.link/dapp/${location.host}/web3/add-network/?chain=${networkConfig?.chain?.id}`,
                  "_self"
                );
              }}
            >
              Open wallet app
            </Button>
          </>
        )}

        <TouchableHighlight
          theme="text"
          className="mt-4 flex items-center"
          display="flex"
          onClick={onClose}
        >
          <div className="font-semibold text-primary">Close</div>
        </TouchableHighlight>
      </div>
    </Modal>
  );
};

export default NetworkAddModal;
