import store, { actions } from "@/stores";
import type { ActionSheet } from "@/types/store";
import type { ClickActionSheetEvent } from "@/types/events";
import router from "next/router";
import { Chain } from "wagmi";
import { switchNetwork as _switchNetwork } from "wagmi/actions";
import { supportChains } from "@/config/config";
import mixpanel from "mixpanel-browser";

export function openTopActionSheet(title: string, sheets: ActionSheet[]) {
  store.dispatch(
    actions.ui.showTopActionSheet({
      sheets,
      title,
    })
  );
}

export function openWebsite(url: string) {
  const domains = ["karatdao.com"];
  const whiteList = [...domains];
  domains.forEach((domain) => {
    whiteList.push(`www.${domain}`);
  });
  const website = new URL(url);
  if (website.origin === window.location.host) {
    return router.push(website.pathname);
  }
  if (whiteList.includes(website.host)) {
    return store.dispatch(
      actions.ui.showPageView({
        url,
      })
    );
  }
  window.open(url, "_blank");
}

export function hideTopActionSheet() {
  store.dispatch(actions.ui.hideTopActionSheet());
}

export function handleActionSheet(event: ClickActionSheetEvent) {
  if (event.sheet.id === "space-staking") {
    router.push(`/space/${event.sheet.meta.planetId}/staking`);
  }
  if (event.sheet.id === "space-information") {
    router.push(`/space/${event.sheet.meta.planetId}/info`);
  }
  hideTopActionSheet();
}

export function navigateBack() {
  if (history.length <= 1) {
    router.push("/");
  } else {
    router.back();
  }
}

export function changeTheme(theme: string) {
  document
    .querySelector('meta[name="theme-color"]')
    ?.setAttribute("content", theme);
}

const toHex = (num: number) => {
  return "0x" + num.toString(16);
};

export async function addNetwork(chain: Chain) {
  console.log("add network", chain);
  const wallet = store.getState().account.wallet;
  console.log("wallet", wallet);
  if (wallet) {
    let connector = wallet.connector;
    if (!connector.isEmbeddedWallet && connector.isWalletConnect) {
      console.log("add network dialog");
      store.dispatch(
        actions.ui.addNetwork({
          chain,
          opened: false,
        })
      );

      return;
    }
  }

  console.log("rpc network");

  try {
    //@ts-ignore
    if (window.ethereum) {
      console.log("add network");
      const rpcUrls = chain.rpcUrls.default.http;

      const params = {
        chainId: toHex(chain.id), // A 0x-prefixed hexadecimal string
        chainName: chain.name,
        nativeCurrency: {
          name: chain.nativeCurrency.name,
          symbol: chain.nativeCurrency.symbol, // 2-6 characters long
          decimals: chain.nativeCurrency.decimals,
        },
        rpcUrls,
        blockExplorerUrls: [chain?.blockExplorers?.default.url],
      };
      //@ts-ignore
      const result = await window.ethereum.request({
        method: "wallet_addEthereumChain",
        params: [params],
      } as any);
      console.log("add network result", result);
      return result;
    } else {
      throw new Error("No Ethereum Wallet");
    }
  } catch (error) {
    console.log(error);
    return false;
  }
}

export async function switchNetwork(chainId: number) {
  const chain = supportChains.find((item) => {
    return item.id === chainId;
  });
  if (!chain) {
    throw new Error(`chainId ${chainId} not support`);
  }
  try {
    const switchedChain = await _switchNetwork({
      chainId: chain.id,
    });
    if (switchedChain.id !== chain.id) {
      throw new Error(`switch chain ${chain.id} fail`);
    }
    return true;
  } catch (e: any) {
    mixpanel.track("switch_chain_fail", {
      error: e.message,
      chainId: chainId,
    });
    return false;
  }
}

export async function switchOrAddNetwork(chainId: number) {
  let switchSuccess = await switchNetwork(chainId);

  if (switchSuccess) {
    return true;
  }
  const chain = supportChains.find((item) => {
    return item.id === chainId;
  });
  if (!chain) {
    throw new Error(`chainId ${chainId} not support`);
  }
  await addNetwork(chain);
  return false;
}
