import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { actions as accountActions } from "./account";
import store from "store2";
import { LoginState } from "@/types/store";

const SessionStorageKey = "Session";
const UserStorageKey = "User";

const initApp = createAsyncThunk(
  "app/init",
  async (_, { getState, dispatch }) => {
    await new Promise((resolve) => {
      setTimeout(resolve, 500);
    });
    const session = store.get(SessionStorageKey);
    const user = store.get(UserStorageKey);
    if (session) {
      await dispatch(accountActions.setSession(session));
    }
    if (user) {
      await dispatch(accountActions.setUser(user));
    }

    if (session && user) {
      await dispatch(accountActions.changeLoginState(LoginState.Login));
    }
  }
);

const slice = createSlice({
  name: "app",
  initialState: {
    inited: false,
  } as {
    inited: boolean;
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(initApp.fulfilled, (state, action) => {
      state.inited = true;
    });
  },
});

export const actions = {
  ...slice.actions,
  initApp,
};

export default slice.reducer;
