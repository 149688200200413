export const routers = {
  messages: {
    path: "/messages",
    theme: "#F87450",
    isTab: true,
  },
  explore: {
    path: "/spaces",
    theme: "#1D1D1D",
    isTab: true,
  },
  staking: {
    path: "/staking",
    theme: "#1D1D1D",
    isTab: true,
  },
  news: {
    path: "/news",
    theme: "#1D1D1D",
    isTab: true,
  },
  stakingSpace: {
    path: "/staking/space/[id]",
    theme: "#1D1D1D",
    isTab: false,
  },
  stakingRules: {
    path: "/staking/rules",
    theme: "#1D1D1D",
    isTab: false,
  },
  networkStakedAmountRule: {
    path: "/staking/rules/network-stake-amount",
    theme: "#1D1D1D",
    isTab: false,
  },
  index: {
    path: "/",
    theme: "#1D1D1D",
    isTab: true,
  },
  account: {
    path: "/account",
    theme: "#1D1D1D",
    isTab: true,
  },
  accountProfile: {
    path: "/account/profile",
    theme: "#1D1D1D",
    isTab: false,
  },
  accountWithdraw: {
    path: "/account/withdraw",
    theme: "#1D1D1D",
    isTab: false,
  },
  accountBalance: {
    path: "/account/balance",
    theme: "#1D1D1D",
    isTab: false,
  },
  accountWallet: {
    path: "/account/wallet",
    theme: "#1D1D1D",
    isTab: false,
  },
  accountSettings: {
    path: "/account/settings",
    theme: "#1D1D1D",
    isTab: false,
  },
  accountAddNetwork: {
    path: "/account/add-network",
    theme: "#1D1D1D",
    isTab: false,
  },
  accountDepositKAT: {
    path: "/account/deposit/kat",
    theme: "#1D1D1D",
    isTab: false,
  },
  accountDeposit: {
    path: "/account/deposit",
    theme: "#1D1D1D",
    isTab: false,
  },
  space: {
    path: "/space/[id]",
    theme: "#F87450",
    isTab: false,
  },
  spaceStaking: {
    path: "/space/[id]/staking",
    theme: "#1D1D1D",
    isTab: false,
  },
  spaceProfit: {
    path: "/space/[id]/profit",
    theme: "#1D1D1D",
    isTab: false,
  },
  spaceHowToJoin: {
    path: "/space/[id]/how-to-join",
    theme: "#1D1D1D",
    isTab: false,
  },
  spaceInfo: {
    path: "/space/[id]/info",
    theme: "#1D1D1D",
    isTab: false,
  },
  spaceInvite: {
    path: "/space/[id]/invite",
    theme: "#F87450",
    isTab: false,
  },
  spaceJoin: {
    path: "/space/[id]/join",
    theme: "#1D1D1D",
    isTab: false,
  },
  spaceActivate: {
    path: "/space/[id]/activate",
    theme: "#1D1D1D",
    isTab: false,
  },
  createSpaceGuide: {
    path: "/guides/how-to-create-a-space",
    theme: "#1D1D1D",
    isTab: false,
  },
  addHomescreenGuide: {
    path: "/guides/add-to-homescreen",
    theme: "#1D1D1D",
    isTab: false,
  },
  userProfile: {
    path: "/user/[id]",
    theme: "#1D1D1D",
    isTab: false,
  },
  stakingDetail: {
    path: "/staking/[id]",
    theme: "#1D1D1D",
    isTab: false,
  },
  receiveToken: {
    path: "/account/receive_token",
    theme: "#1D1D1D",
    isTab: false,
  },
  web3AddNetwork: {
    path: "/web3/add-network",
    theme: "#1D1D1D",
    isTab: false,
  },
  verify: {
    path: "/verify",
    theme: "#1D1D1D",
    isTab: false,
  },
};

export function getRouteByPath(path: string) {
  return Object.values(routers).find((router) => {
    return router.path === path;
  });
}

export function needLogin(path: string) {
  return (
    [
      routers.spaceInvite.path,
      routers.createSpaceGuide.path,
      routers.spaceHowToJoin.path,
      routers.web3AddNetwork.path,
    ].indexOf(path) === -1
  );
}
