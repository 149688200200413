import { useEffect, useState } from "react";
import OneSignal from "react-onesignal";
import useAccount from "./useAccount";
import store, { useTypedSelector, actions } from "@/stores";

export function useSetupOneSignal() {
  const { isLogin, user } = useAccount();
  const oneSignalInited = useTypedSelector((state) => {
    return state.account.oneSignalInited;
  });
  const [optedIn, setOptedIn] = useState(false);
  const [notificationEnable, setNotificationEnable] = useState(false);
  const hasUserSpace = !!user?.space;

  useEffect(() => {
    if (isLogin) {
      OneSignal.init({
        appId: process.env.NEXT_PUBLIC_ONESIGNAL_ID as string,
        allowLocalhostAsSecureOrigin: true,
        serviceWorkerPath: "/push/onesignal/OneSignalSDKWorker.js",
        serviceWorkerParam: {
          scope: "/push/onesignal/",
        },
        autoResubscribe: true,
        promptOptions: {
          autoPrompt: false,
        },
      }).then(() => {
        store.dispatch(actions.account.setOneSignalInited(true));
      });
    }
  }, [isLogin]);

  useEffect(() => {
    if (isLogin && hasUserSpace) {
      if (!oneSignalInited) {
        return;
      }
      const account = store.getState().account;
      OneSignal.login(
        String(account.user!.userId),
        account.session?.onesignal_token
      ).then(() => {
        setTimeout(() => {
          OneSignal.Slidedown.promptPush();
        }, 10000);
        console.log(
          "OneSignal Notifications is support",
          OneSignal.Notifications.isPushSupported()
        );
        console.log(
          "OneSignal Subscription id",
          OneSignal.User.PushSubscription.id
        );
        setNotificationEnable(OneSignal.Notifications.permission);
        setOptedIn(OneSignal.User.PushSubscription.optedIn || false);
      });
    } else {
      OneSignal.logout();
    }
  }, [isLogin, hasUserSpace, oneSignalInited]);

  useEffect(() => {
    function onChange(event: {
      current: {
        id: string;
        token: string;
        optedIn: boolean;
      };
    }) {
      setOptedIn(event.current.optedIn);
    }
    function onChangePermission(permission: boolean) {
      setNotificationEnable(permission);
    }
    OneSignal.User.PushSubscription.addEventListener("change", onChange as any);
    OneSignal.Notifications.addEventListener(
      "permissionChange",
      onChangePermission
    );
    return () => {
      OneSignal.User.PushSubscription.removeEventListener(
        "change",
        onChange as any
      );
      OneSignal.Notifications.removeEventListener(
        "permissionChange",
        onChangePermission
      );
    };
  }, []);

  useEffect(() => {
    store.dispatch(
      actions.ui.setNotificationEnable(optedIn && notificationEnable)
    );
  }, [optedIn, notificationEnable]);
}
