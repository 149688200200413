import type { DynamicContextProps } from "@dynamic-labs/sdk-react-core";
import { mainnet, zkSync } from "wagmi/chains";
import store, { actions } from "@/stores";
import { EthereumWalletConnectors } from "@dynamic-labs/ethereum";
import mixpanel from "mixpanel-browser";
import { signMessage } from "wagmi/actions";
import store2 from "store2";
import { FilterWallets } from "@dynamic-labs/sdk-react-core";

export async function verifyWallet() {
  const wallet = store.getState().account.wallet;
  const address = store.getState().account.session?.address;
  if (!address) {
    throw new Error("login state error");
  }
  if (!wallet?.connector?.isEmbeddedWallet) {
    return true;
  }
  const key = `${address}_verified`;
  if (store2.get(key) === true) {
    return true;
  }
  await signMessage({
    message:
      "Before you can proceed, we need to verify your Passkey and wallet.",
  });
  store2.set(key, true);
  return true;
}

export const contextConfig = {
  settings: {
    appName: "Karat.club",
    appLogoUrl: "https://www.karat.club/assets/logo.png",
    environmentId: process.env.NEXT_PUBLIC_DYNAMIC_ENV_ID as string,
    walletsFilter: FilterWallets([]),
    initialAuthenticationMode: "connect-and-sign",
    walletConnectors: [EthereumWalletConnectors],
    eventsCallbacks: {
      onConnectSuccess: (args) => {
        console.log("onConnectSuccess was called", args);
      },
      onLogout(args) {
        store.dispatch(actions.account.logout());
        mixpanel.track("dynamic_logout", {
          user: args,
        });
      },
      onAuthFlowOpen: () => {
        const user = store.getState().account.user;
        mixpanel.track("dynamic_auth_open", {
          user,
        });
      },
      onAuthFlowClose: () => {
        const user = store.getState().account.user;
        mixpanel.track("dynamic_auth_close", {
          user,
        });
      },
      onEmbeddedWalletCreated(args) {
        mixpanel.track("dynamic_embed_wallet_create_success", {
          address: args?.address,
          chain: args?.chain,
        });
      },
      onAuthSuccess: (args) => {
        mixpanel.track("dynamic_auth_success", {
          user: args.user,
          primaryWallet: args.primaryWallet,
          isAuthenticated: args.isAuthenticated,
        });
      },
      onEmailVerificationSuccess: (args) => {
        const user = store.getState().account.user;
        mixpanel.track("dynamic_email_verify_success", {
          args: args,
          user,
        });
      },
      onEmailVerificationFailure: (args) => {
        const user = store.getState().account.user;
        mixpanel.track("dynamic_email_verify_fail", {
          args: args,
          user,
        });
      },
    },
  },
} as DynamicContextProps;
