import { FunctionComponent, useState } from "react";
import {
  Modal,
  ActionIcon,
  Select,
  Button,
  Group,
  Switch,
  TextInput,
} from "@mantine/core";
import { AiFillSetting } from "react-icons/ai";
import store, { actions, useTypedSelector } from "@/stores";
import { useRouter } from "next/router";
import { backends } from "@/config/config";

const DevtoolMenu: FunctionComponent = () => {
  const [opened, setOpened] = useState(false);
  const config = useTypedSelector((state) => {
    return state.devtool.config;
  });
  const router = useRouter();
  const featureList = useTypedSelector((state) => {
    return state.features.list;
  });

  const selectBackends = [...backends];
  if (
    config.backend &&
    !selectBackends.find((item) => {
      return item.value === config.backend;
    })
  ) {
    selectBackends.unshift({
      value: config.backend,
      label: "custom",
    });
  }

  if (!featureList.vConsole) {
    return null;
  }

  console.log("selectBackends", selectBackends);

  return (
    <>
      <div className="fixed z-[999] bottom-4 right-4">
        <ActionIcon
          color="gray"
          size="lg"
          radius="xl"
          variant="filled"
          onClick={() => setOpened(true)}
        >
          <AiFillSetting size={24} />
        </ActionIcon>
      </div>
      <Modal
        opened={opened}
        centered
        onClose={() => setOpened(false)}
        title="Dev Config"
        zIndex={999}
      >
        <div>
          <Select
            label="backend"
            placeholder="Pick one"
            classNames={{
              root: "mb-4",
            }}
            value={config.backend}
            onChange={(val) => {
              store.dispatch(
                actions.devtool.setConfig({
                  ...config,
                  backend: val as string,
                })
              );
            }}
            comboboxProps={{ zIndex: 1000 }}
            searchable
            data={selectBackends as any}
          />

          <TextInput
            label="Custom wallet address"
            placeholder="0x0f9..13"
            classNames={{
              root: "mb-4",
            }}
            defaultValue={config.customAddress}
            onBlur={(e) => {
              store.dispatch(
                actions.devtool.setConfig({
                  ...config,
                  customAddress: e.currentTarget.value,
                })
              );
            }}
          />

          <Group align="cente">
            <Button
              classNames={{
                root: "mx-auto",
              }}
              onClick={() => {
                router.reload();
              }}
            >
              Reload Page
            </Button>
          </Group>
        </div>
      </Modal>
    </>
  );
};

export default DevtoolMenu;
