import mitt, { Emitter } from "mitt";
import {
  ClickActionSheetEvent,
  UnStakeBatchInfo,
  ClaimStakerBatchInfo,
  ClaimClaimerInfo,
  StakeInfo,
  ClaimValidatorInfo,
  ClaimLieutenantInfo,
  UpdateCaptainDescriptionInfo,
  UpdateCaptainAvatarInfo,
  UpdateCaptainInfo,
  TwitterConnected,
  UnStakeInfo,
} from "@/types/events";

type Events = {
  clickActionSheet: ClickActionSheetEvent;
  stakeSuccess: StakeInfo;
  unStakeBatchSuccess: UnStakeBatchInfo;
  unStakeSuccess: UnStakeInfo;
  claimStakerBatch: ClaimStakerBatchInfo;
  claimClaimer: ClaimClaimerInfo;
  claimValidator: ClaimValidatorInfo;
  claimLieutenant: ClaimLieutenantInfo;
  updateCaptainDescription: UpdateCaptainDescriptionInfo;
  updateCaptainAvatar: UpdateCaptainAvatarInfo;
  updateCaptainInfo: UpdateCaptainInfo;
  twitterConnected: TwitterConnected;
  loginSuccess: {};
  spaceInited: {};
};

const events: Emitter<Events> = mitt();

export default events;
