import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import store from "store2";

import type { Session, UserInfo } from "@/types/account";
import { LoginState } from "@/types/store";
import { Wallet } from "@dynamic-labs/sdk-react-core";

const SessionStorageKey = "Session";
const UserStorageKey = "User";

const slice = createSlice({
  name: "account",
  initialState: {
    session: undefined,
    user: undefined,
    logining: false,
    loginState: LoginState.Idle,
    verifying: false,
    wallet: null,
    oneSignalInited: false,
  } as {
    session: Session | undefined;
    user: UserInfo | undefined;
    logining: boolean;
    loginState: LoginState;
    verifying: boolean;
    wallet: Wallet | null;
    oneSignalInited: boolean;
  },
  reducers: {
    setOneSignalInited(state, action: PayloadAction<boolean>) {
      state.oneSignalInited = action.payload;
    },
    setWallet(state, action: PayloadAction<Wallet | null>) {
      state.wallet = action.payload;
    },
    setVerifying(state, action: PayloadAction<boolean>) {
      state.verifying = action.payload;
    },
    changeLoginState(state, action: PayloadAction<LoginState>) {
      state.loginState = action.payload;
    },
    setSession(state, action: PayloadAction<Session>) {
      state.session = action.payload;
      store.set(SessionStorageKey, action.payload);
    },
    setUser(state, action: PayloadAction<UserInfo>) {
      state.user = action.payload;
      store.set(UserStorageKey, action.payload);
    },
    setLogining(state, action: PayloadAction<boolean>) {
      state.logining = action.payload;
    },
    removeSession(state) {
      state.session = undefined;
      store.remove(SessionStorageKey);
    },
    logout(state) {
      state.verifying = false;
      state.loginState = LoginState.Idle;
      state.session = undefined;
      store.remove(SessionStorageKey);
      state.user = undefined;
      store.remove(UserStorageKey);
    },
  },
});

export const actions = {
  ...slice.actions,
};

export default slice.reducer;
