import { combineReducers } from "@reduxjs/toolkit";
import ui, { actions as uiActions } from "./ui";
import account, { actions as accountActions } from "./account";
import bridge, { actions as bridgeActions } from "./bridge";
import chat, { actions as chatActions } from "./chat";
import app, { actions as appActions } from "./app";
import features, { actions as featuresActions } from "./features";
import devtool, { actions as devtoolActions } from "./devtool";
import news, { actions as newsActions } from "./news";

export default combineReducers({
  ui,
  account,
  bridge,
  chat,
  app,
  features,
  devtool,
  news,
});

export const actions = {
  ui: uiActions,
  account: accountActions,
  bridge: bridgeActions,
  chat: chatActions,
  app: appActions,
  features: featuresActions,
  devtool: devtoolActions,
  news: newsActions,
};
