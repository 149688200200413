import request from "@/utils/request";
import { UpdateUserInfoParams, DynamicVerifyParams } from "@/types/api";
import { UserInfo, Session } from "@/types/account";

export const updateUserInfo = async (
  data: UpdateUserInfoParams
): Promise<UserInfo> => {
  return await request.rpc("user/updateUserInfo", data);
};

export const getUserInfo = async (data: {
  userId: number;
}): Promise<UserInfo> => {
  return await request.rpc("user/getUserInfo", data);
};

export const dynamicVerify = async (
  data: DynamicVerifyParams
): Promise<Session> => {
  const resp = await request.rpc("dynamic/verify", data);
  // const resp = await request.rpc("siwe/debug", {
  //   address: data.walletAddr,
  // });
  return {
    ...resp,
    dynamicId: data.useridx,
  };
};

export const dynamicAdminVerify = async (
  data: DynamicVerifyParams & {
    address: string;
  }
): Promise<Session> => {
  const resp = await request.rpc("dynamic/userbyadmin", data);
  return {
    ...resp,
    dynamicId: data.useridx,
  };
};

export const bindTwitter = async (
  data: UpdateUserInfoParams
): Promise<{
  twitter: {
    id: string;
    name: string;
    username: string;
  };
}> => {
  return await request.rpc("account/bind_twitter", data);
};

export const verify = async (data: {
  code: string;
  scene: number;
}): Promise<string> => {
  return await request.rpc("karatclub/verify", data);
};
