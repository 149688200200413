import { FunctionComponent, useEffect, useState } from "react";
import {
  useChannelListContext,
  ChannelListProvider,
} from "@sendbird/uikit-react/ChannelList/context";
import useGlobalState from "@/hooks/useGlobalState";
import store, { actions } from "@/stores";
import type { GroupChannel } from "@sendbird/chat/groupChannel";

function hasMessageUnread(channels: GroupChannel[]) {
  let rtn = false;
  channels.forEach((channel) => {
    if ((channel?.unreadMessageCount || 0) > 0) {
      rtn = true;
    }
  });
  return rtn;
}

function GroupList() {
  const { allChannels } = useChannelListContext();
  const [_, setChannels] = useGlobalState("channels");

  useEffect(() => {
    setChannels(allChannels);
    const messageUnread = hasMessageUnread(allChannels);
    if (store.getState().ui.hasMessageUnread !== messageUnread) {
      store.dispatch(actions.ui.setMessageUnread(messageUnread));
    }
  }, [allChannels, setChannels]);

  return null;
}

const UserChannelList: FunctionComponent = function () {
  return (
    <ChannelListProvider
      queries={{
        channelListQuery: {
          includeEmpty: true,
          customTypesFilter: ["staking"],
        },
      }}
      className={"!w-full"}
    >
      <GroupList></GroupList>
    </ChannelListProvider>
  );
};

export default UserChannelList;
