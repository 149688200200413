import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  ActionSheetsModalConfig,
  GroupDetailModalConfig,
  UIState,
  ContractStatusModalConfig,
  StakingTab,
  PageViewConfig,
  ExploreTab,
  AddNetworkConfig,
  LoginModalConfig,
  CustomUserLoginModalConfig,
} from "@/types/store";

import store from "store2";

const slice = createSlice({
  name: "ui",
  initialState: {
    appInited: false,
    groupInviteModal: null,
    loginModal: null,
    joinGroupModal: null,
    groupDetailModal: null,
    welcomeModal: false,
    topActionSheets: null,
    contractStatusModal: null,
    stakingTab: StakingTab.stake,
    pageView: null,
    spacesTab: ExploreTab.amount,
    devMode: store.get("_dev") || false,
    addNetwork: null,
    enableBrowserVisit: store.get("_browserVisit") || false,
    hasMessageUnread: false,
    notificationEnable: false,
    customUserLoginModal: null,
  } as UIState,
  reducers: {
    setNotificationEnable(state, action: PayloadAction<boolean>) {
      state.notificationEnable = action.payload;
    },
    setMessageUnread(state, action: PayloadAction<boolean>) {
      state.hasMessageUnread = action.payload;
    },
    setBrowserVisit(state, action: PayloadAction<boolean>) {
      state.enableBrowserVisit = action.payload;
      store.set("_browserVisit", true);
    },
    setDevMode(state, action: PayloadAction<boolean>) {
      state.devMode = action.payload;
      store.set("_dev", action.payload);
    },
    addNetwork(state, action: PayloadAction<AddNetworkConfig>) {
      // @ts-ignore
      state.addNetwork = action.payload;
    },
    setSpaceTab(state, action: PayloadAction<ExploreTab>) {
      state.spacesTab = action.payload;
    },
    setAppInited(state, action: PayloadAction<boolean>) {
      state.appInited = action.payload;
    },
    showGroup(state, action: PayloadAction<GroupDetailModalConfig>) {
      state.groupDetailModal = action.payload;
    },
    hideGroup(state) {
      state.groupDetailModal = null;
    },
    showTopActionSheet(state, action: PayloadAction<ActionSheetsModalConfig>) {
      state.topActionSheets = action.payload;
    },
    hideTopActionSheet(state) {
      state.topActionSheets = null;
    },
    updateContractStatusModal(
      state,
      action: PayloadAction<ContractStatusModalConfig>
    ) {
      state.contractStatusModal = action.payload;
    },
    changeStakingTab(state, action: PayloadAction<StakingTab>) {
      state.stakingTab = action.payload;
    },
    showPageView(state, action: PayloadAction<PageViewConfig>) {
      state.pageView = action.payload;
    },
    setLoginModal(state, action: PayloadAction<LoginModalConfig>) {
      state.loginModal = action.payload;
    },
    setCustomUserLoginModal(
      state,
      action: PayloadAction<CustomUserLoginModalConfig>
    ) {
      state.customUserLoginModal = action.payload;
    },
  },
});

export const actions = {
  ...slice.actions,
};

export default slice.reducer;
