import { useEffect, useState, FunctionComponent } from "react";
import IconButton from "@/components/common/IconButton";
import { ArrowDownIcon, EditIcon, AvatarIcon } from "@/components/common/icons";
import { navigateBack } from "@/services/app";
import { TextInput, Textarea, Button } from "@mantine/core";
import * as yup from "yup";
import { useFormik } from "formik";
import Image from "next/image";
import { Upload } from "antd";
import ImgCrop from "antd-img-crop";
import type { UploadProps } from "antd/es/upload/interface";
import { toast } from "react-toastify";
import { fileToBase64 } from "@/utils/format";
import useRequest from "@/hooks/useRequest";
import { UserInfo } from "@/types/account";
import { updateUserInfo } from "@/services/user";
import { uploadAvatar } from "@/services/image";
import ToastLoading from "@/components/common/ToastLoading";
import useAccount from "@/hooks/useAccount";
import store, { actions } from "@/stores";
import UserTwitter from "@/components/account/UserTwitter";
import { TwitterProfile } from "@/types/api";

export type RequiredConfig = {
  twitter?: boolean;
  avatar?: boolean;
  name?: boolean;
  introduction?: boolean;
};

const UserProfile: FunctionComponent<{
  onSave: any;
  btnText?: string;
  required: RequiredConfig;
  btnLoading?: boolean;
  syncMode?: boolean;
  hideTwitter?: boolean;
  defaultProfile?: {
    avatar?: string;
    name?: string;
    introduction?: string;
    twitter?: any;
  };
}> = function ({
  onSave,
  btnText,
  required,
  btnLoading,
  syncMode,
  hideTwitter,
  defaultProfile,
}) {
  const [nameFocus, setNameFocus] = useState(false);
  const [descFocus, setDescFocus] = useState(false);
  const [saving, setSaving] = useState(false);
  const { user } = useAccount();
  const [verifying, setVerifying] = useState(false);

  const {
    data: userInfo,
    isLoading,
    error,
  } = useRequest<UserInfo>(
    user
      ? {
          function: "user/getUserInfo",
          data: {
            userId: user.userId,
          },
        }
      : null,
    {
      refreshInterval: 2000,
      onSuccess(user) {
        store.dispatch(actions.account.setUser(user));
      },
    }
  );

  const schema = yup.object({
    name: required.name
      ? yup.string().required().max(20)
      : yup.string().notRequired().max(20),
    avatar: required.avatar
      ? yup.string().required()
      : yup.string().notRequired(),
    introduction: required.introduction
      ? yup.string().required().max(300)
      : yup.string().notRequired().max(300),
    twitter: required.twitter
      ? yup.object().required()
      : yup.object().notRequired(),
  });

  const formik = useFormik({
    initialValues: defaultProfile || schema.getDefault(),
    validationSchema: schema,
    onSubmit: async (values) => {
      if (saving) {
        return;
      }
      try {
        setSaving(true);
        const data: any = {};
        Object.keys(values).forEach((key) => {
          // @ts-ignore
          if (values[key] !== null) {
            // @ts-ignore
            data[key] = values[key];
          }
        });
        const user = await updateUserInfo(data);
        const _user = store.getState().account.user;
        store.dispatch(
          actions.account.setUser({
            ..._user,
            ...user,
          })
        );
        onSave();
      } catch (e: any) {
        toast.error(e.message);
      } finally {
        setSaving(false);
      }
    },
  });

  useEffect(() => {
    if (!defaultProfile && userInfo) {
      formik.setValues({
        name: userInfo.name,
        avatar: userInfo.avatar,
        introduction: userInfo.introduction,
      } as any);
    }
  }, [userInfo, defaultProfile]);

  useEffect(() => {
    formik.setFieldValue("twitter", user?.twitter);
  }, [user]);

  const showAvatarError = formik.errors.avatar && formik.touched.avatar;
  const showNameError = formik.errors.name && formik.touched.name;
  const showIntroductionError =
    formik.errors.introduction && formik.touched.introduction;
  const showTwitterError = formik.errors.twitter && verifying;

  const [processing, setProcessing] = useState(false);

  const onChange: UploadProps["beforeUpload"] = async (file) => {
    if (!file || typeof file.size === "undefined" || processing) {
      return;
    }
    if (file.size > 1024 * 1024 * 2) {
      toast.error(`Image size cannot exceed 2M`);
      return false;
    }
    await toast.promise(
      async () => {
        try {
          setProcessing(true);
          const fileName = file.name;
          const base64 = await fileToBase64(file as File);
          const resp = await uploadAvatar({
            base64: base64.split(",")[1],
            filename: fileName,
            uploadType: "user_avatar",
          });
          formik.setFieldValue("avatar", resp.url);
        } finally {
          setProcessing(false);
        }
      },
      {
        pending: {
          render: () => {
            return <ToastLoading text="Uploading"></ToastLoading>;
          },
        },
        success: "Image upload success",
        error: {
          render({ data }) {
            // @ts-ignore
            return data?.message;
          },
        },
      }
    );
  };

  return (
    <div className="flex flex-col gap-6">
      {!(syncMode && (!user?.twitter || user.twitterBinding)) &&
        !hideTwitter && (
          <div>
            <UserTwitter
              onImport={(profile: TwitterProfile) => {
                formik.setFieldValue("name", profile.userName);
                formik.setFieldValue("avatar", profile.avatar);
                formik.setFieldValue("introduction", profile.description);
              }}
              onDisconnect={() => {
                formik.setFieldValue("twitter", undefined);
              }}
              syncMode={syncMode}
            ></UserTwitter>
            {showTwitterError && (
              <div className="text-error mt-2 text-sm pl-2">
                Your account is not linked to Twitter yet.
              </div>
            )}
          </div>
        )}

      <div>
        <div className="font-semibold pl-2">
          Profile photo
          {required.avatar && <span className="text-primary">*</span>}
        </div>
        <div className={`h-[120px] w-full flex mt-4 relative`}>
          {formik.values.avatar ? (
            <Image
              src={formik.values.avatar}
              width={120}
              height={120}
              alt="Profile photo"
              className="rounded-full"
            ></Image>
          ) : (
            <div className="w-[120px] h-[120px] rounded-full border border-[#eee] flex items-center justify-center">
              <AvatarIcon></AvatarIcon>
            </div>
          )}

          <div className={`absolute left-[86px] bottom-0`}>
            <ImgCrop modalTitle="Edit picture" rotationSlider={false}>
              <Upload
                listType="text"
                showUploadList={false}
                maxCount={1}
                beforeUpload={onChange}
              >
                <IconButton>
                  <EditIcon></EditIcon>
                </IconButton>
              </Upload>
            </ImgCrop>
          </div>
        </div>
        {showAvatarError && (
          <div className="text-error mt-2 text-sm pl-2">
            {formik.errors.avatar}
          </div>
        )}
      </div>
      <div>
        <div className="font-semibold pl-2">
          Profile name{required.name && <span className="text-primary">*</span>}
        </div>
        <TextInput
          className="mt-4"
          classNames={{
            input: `!py-4 !px-6 !h-[60px] ${
              nameFocus ? "!border-primary" : "!border-[#EEE]"
            } !rounded-[100px] ${showNameError ? "!border-error" : ""}`,
          }}
          value={formik.values.name}
          maxLength={20}
          onFocus={() => {
            setNameFocus(true);
          }}
          id="name"
          onBlur={() => {
            setNameFocus(false);
          }}
          onChange={formik.handleChange}
        ></TextInput>
        {showNameError && (
          <div className="text-error mt-2 text-sm pl-2">
            {formik.errors.name}
          </div>
        )}
      </div>
      <div>
        <div className="font-semibold pl-2">
          About me
          {required.introduction && <span className="text-primary">*</span>}
        </div>
        <Textarea
          autosize
          maxLength={300}
          minRows={3}
          value={formik.values.introduction}
          className="mt-4"
          id="introduction"
          placeholder="Add a short description about yourself."
          classNames={{
            input: `!p-6 !border-[#EEE] !rounded-[20px] ${
              descFocus ? "!border-primary" : "!border-[#EEE]"
            } ${showIntroductionError ? "!border-error" : ""}`,
          }}
          onFocus={() => {
            setDescFocus(true);
          }}
          onBlur={() => {
            setDescFocus(false);
          }}
          onChange={formik.handleChange}
        ></Textarea>
        {showIntroductionError && (
          <div className="text-error mt-2 text-sm pl-2">
            {formik.errors.introduction}
          </div>
        )}
      </div>

      <Button
        onClick={() => {
          setVerifying(true);
          formik.submitForm();
        }}
        className="!h-[60px] !bg-primary !rounded-full before:!rounded-full !text-dark1 !font-bold !text-base"
        loading={btnLoading || saving}
      >
        {btnText || "Save"}
      </Button>
    </div>
  );
};

export default UserProfile;
