import { FunctionComponent, useEffect, useState } from "react";
import { use100vh } from "react-div-100vh";
import Login from "./Login";
import useAccount from "@/hooks/useAccount";
import store, { actions, useTypedSelector } from "@/stores";
import { AiOutlineClose } from "react-icons/ai";

const LoginDialog: FunctionComponent = () => {
  const height = use100vh();
  const { isLogin } = useAccount();
  const loginConfig = useTypedSelector((state) => {
    return state.ui.loginModal;
  });
  useEffect(() => {
    if (isLogin) {
      store.dispatch(actions.ui.setLoginModal(null));
    }
  }, [isLogin]);

  if (!loginConfig) {
    return null;
  }

  return (
    <div
      className="fixed z-[999] w-full bg-dark1 flex items-center justify-center top-0 left-0"
      style={{
        height: height ? `${height}px` : "100vh",
      }}
    >
      <div
        className="absolute top-5 right-5 z-[9999]"
        onClick={() => {
          store.dispatch(actions.ui.setLoginModal(null));
        }}
      >
        <AiOutlineClose className="text-[30px] text-primary"></AiOutlineClose>
      </div>
      <Login></Login>
    </div>
  );
};

export default LoginDialog;
