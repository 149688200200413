import {
  mainnet,
  zkSyncTestnet,
  zkSync,
  bsc,
  polygon,
  goerli,
} from "wagmi/chains";

export const zksyncChainId =
  process.env.NEXT_PUBLIC_ZKSYNC_TEST === "true" ? zkSyncTestnet.id : zkSync.id;

export const isMaintenance =
  process.env.NEXT_PUBLIC_MAINTENANCE_MODE === "true";

export const maintenanceApiMessage =
  "Server is under maintenance, please try again later";

export const supportChains = [
  mainnet,
  zkSyncTestnet,
  zkSync,
  bsc,
  polygon,
  goerli,
];

export const serve_address = process.env.NEXT_PUBLIC_BACKEND_API;

export const backends = [
  {
    value: "https://api-dev.karatdao.com/test_backend/dev/action",
    label: "dev",
  },
  {
    value: "https://api.karatdao.com/read/action",
    label: "admin",
  },
  {
    value: "https://api.karatdao.com/pre/action",
    label: "pre-release",
  },
  {
    value: "https://api-dev.karatdao.com/test_backend/test_backend/test/action",
    label: "test",
  },
  { value: process.env.NEXT_PUBLIC_BACKEND_API, label: "prod" },
  {
    value: "https://api-dev.karatdao.com/test_backend/zado/action",
    label: "zado",
  },
  {
    value: "https://api-dev.karatdao.com/test_backend/lemon/action",
    label: "lemon",
  },
  {
    value: "https://api-dev.karatdao.com/test_backend/charles/action",
    label: "charles",
  },
  {
    value: "http://localhost:3002/action",
    label: "local",
  },
];
