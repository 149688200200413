import { useCallback, useEffect, useState } from "react";
import { useDynamicContext } from "@dynamic-labs/sdk-react-core";
import { useContractRead } from "wagmi";
import { KATContractInfo, stakingContractInfo } from "@/services/contract";
import BigNumber from "bignumber.js";
import { useTypedSelector } from "@/stores";
import { LoginState } from "@/types/store";
import { useBalance } from "wagmi";
import { zksyncChainId } from "@/config/config";
import mixpanel from "mixpanel-browser";
import store from "@/stores";

export default function useAccount() {
  const {
    user: dynamicUser,
    primaryWallet,
    setShowAuthFlow,
    handleLogOut,
  } = useDynamicContext();

  const [balance, setBalance] = useState(new BigNumber(0));
  const [v1KATbalance, setV1KATBalance] = useState(new BigNumber(0));
  const [ethBalance, setETHBalance] = useState(new BigNumber(0));

  const [isLogin, setLogin] = useState(false);
  const { user, loginState } = useTypedSelector((state) => {
    return state.account;
  });

  const customAddress = useTypedSelector((state) => {
    return state.devtool.config.customAddress;
  });

  const featureList = useTypedSelector((state) => {
    return state.features.list;
  });

  const address = featureList.vConsole
    ? customAddress || primaryWallet?.address
    : primaryWallet?.address;

  const { data: contractData, refetch: refreshBalance } = useContractRead({
    watch: false,
    scopeKey: "kat-contract",
    functionName: "balanceOf",
    ...KATContractInfo,
    args: [address],
    enabled: !!address,
  });

  const {
    data: ethBalanceData,
    isError,
    isLoading,
  } = useBalance({
    address: address as any,
    chainId: zksyncChainId,
    enabled: !!address,
  });

  const { data: v1KATBalanceData } = useContractRead({
    ...stakingContractInfo,
    functionName: "stakingV1Balance",
    args: [address],
    enabled: !!address,
  });

  useEffect(() => {
    let balance = new BigNumber(0);

    try {
      balance = new BigNumber(String(contractData)).div(1e18);
    } catch (e) {}
    setBalance(balance);
  }, [contractData]);

  useEffect(() => {
    let balance = new BigNumber(0);

    try {
      balance = new BigNumber(String(v1KATBalanceData)).div(1e18);
    } catch (e) {}
    setV1KATBalance(balance);
  }, [v1KATBalanceData]);

  useEffect(() => {
    let balance = new BigNumber(0);

    try {
      balance = new BigNumber(ethBalanceData?.formatted || "0");
    } catch (e) {}
    setETHBalance(balance);
  }, [ethBalanceData]);

  useEffect(() => {
    setLogin(loginState === LoginState.Login);
  }, [loginState]);

  const login = useCallback(async () => {
    if (isLogin) {
      return;
    }
    if (!dynamicUser || !primaryWallet || !primaryWallet.connected) {
      return setShowAuthFlow(true);
    }
    const state = store.getState();
    mixpanel.track("login_click", {
      loginState: state.account.loginState,
      isLogin: false,
      dynamicUser: dynamicUser.wallet,
      primaryWallet: address,
      sessionAddress: state.account.session?.address,
      userAddress: state.account.user?.walletAddress,
    });
    await handleLogOut();
    setShowAuthFlow(true);
  }, [setShowAuthFlow, isLogin, dynamicUser, address]);

  return {
    user,
    login,
    logout: handleLogOut,
    isLogin: loginState === LoginState.Login,
    address: address,
    balance,
    ethBalance,
    v1KATbalance,
    refreshBalance,
    wallet: primaryWallet,
  };
}
