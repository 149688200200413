import { FunctionComponent, useEffect, useState } from "react";
import useAccount from "@/hooks/useAccount";
import { useTypedSelector } from "@/stores";
import { use100vh } from "react-div-100vh";
import { Button } from "@mantine/core";
import { LoginState } from "@/types/store";
import { motion, AnimatePresence } from "framer-motion";
import { useWalletItemActions } from "@dynamic-labs/sdk-react-core";
import { useDynamicContext } from "@dynamic-labs/sdk-react-core";
import TouchableHighlight from "./TouchableHighlight";
import { useDevice } from "@/hooks/useDevice";
import { switchNetwork } from "wagmi/actions";
import { addNetwork } from "@/services/app";
import { zkSync } from "wagmi/chains";

function Login() {
  const height = use100vh();
  const { login } = useAccount();
  const { loginState } = useTypedSelector((state) => {
    return state.account;
  });
  const { openWallet } = useWalletItemActions();
  const { isMetamaskBrowser, isMobile } = useDevice();
  const isMaintenance = process.env.NEXT_PUBLIC_MAINTENANCE_MODE === "true";

  return (
    <AnimatePresence>
      <div
        className="fixed z-[999] w-full bg-dark1 top-0 left-0"
        style={{
          height: height ? `${height}px` : "100vh",
        }}
      >
        <div className="flex flex-col items-center justify-center mt-[30vh] px-4">
          <motion.div
            className="text-primary font-bold text-[56px]"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{
              delay: 0,
              duration: 0.5,
            }}
          >
            Karat.club
          </motion.div>
        </div>
        <motion.div
          className="flex gap-4 flex-col absolute w-full  bottom-10 left-0 px-4"
          initial={{ translateY: "100%" }}
          animate={{ translateY: "0%" }}
          transition={{
            delay: 0,
            duration: 0.2,
          }}
        >
          {isMaintenance ? (
            <div className="w-full mx-auto sm:max-w-[450px]">
              <Button
                fullWidth
                disabled
                className="!bg-primary !h-20 !rounded-full before:!rounded-full !text-base !font-bold !text-dark1"
              >
                Login
              </Button>
              <div className="text-white mt-2 text-center text-xs">
                {process.env.NEXT_PUBLIC_MAINTENANCE_MESSAGE}
              </div>
            </div>
          ) : (
            <div className="w-full mx-auto sm:max-w-[450px]">
              {loginState !== LoginState.Idle ? (
                <Button
                  fullWidth
                  className="!bg-primary !h-20 !rounded-full before:!rounded-full !text-base !font-bold !text-dark1"
                  onClick={login}
                  loading={true}
                >
                  Login
                </Button>
              ) : (
                <>
                  <Button
                    fullWidth
                    className="!bg-primary !h-20 !rounded-full before:!rounded-full !text-base !font-bold !text-dark1"
                    onClick={login}
                    loading={loginState !== LoginState.Idle}
                  >
                    Log in via social
                  </Button>
                  <div className="flex justify-center mt-7">
                    <TouchableHighlight theme="text">
                      <div
                        className="font-bold text-primary"
                        onClick={async () => {
                          if (isMobile && !isMetamaskBrowser) {
                            window.open(
                              `https://metamask.app.link/dapp/${location.host}${location.pathname}`,
                              "_self"
                            );
                          } else {
                            await addNetwork(zkSync);
                            openWallet("metamask");
                          }
                        }}
                      >
                        Log in via Metamask
                      </div>
                    </TouchableHighlight>
                  </div>
                </>
              )}
            </div>
          )}
        </motion.div>
      </div>
    </AnimatePresence>
  );
}

export default Login;
