import { FunctionComponent, useCallback, useEffect, useState } from "react";
import { Modal, Button } from "@mantine/core";
import TouchableHighlight from "../common/TouchableHighlight";
import useRequest from "@/hooks/useRequest";
import { TwitterBinding } from "@/types/api";
import { bindTwitter } from "@/services/user";
import { toast } from "react-toastify";
import { trimAddr } from "@/utils/format";
import useAccount from "@/hooks/useAccount";

const TwitterUnbindDialog: FunctionComponent<{
  opened: boolean;
  close: any;
  twitter: string;
}> = function ({ opened, close, twitter }) {
  const { user } = useAccount();
  const [processing, setProcessing] = useState(false);
  const { data: bindings = [], error } = useRequest<TwitterBinding[]>(
    opened && twitter
      ? {
          function: "account/getTwitterBindings",
          data: {
            twitterUserName: twitter,
          },
        }
      : null
  );
  const unBind = useCallback(async () => {
    if (processing) {
      return;
    }
    try {
      setProcessing(true);
      await bindTwitter({});
      close();
    } catch (e: any) {
      toast.error(e.message);
    } finally {
      setProcessing(false);
    }
  }, [processing]);

  return (
    <Modal
      opened={opened}
      centered
      withCloseButton={false}
      onClose={close}
      size={383}
      radius={40}
      zIndex={1000}
      classNames={{
        content: "!bg-dark1 !py-10 !px-6",
        body: "!p-0",
      }}
    >
      <div className="flex flex-col items-center justify-center text-white">
        <div className="text-[20px] font-bold text-center max-w-[265px]">
          Unlink wallets to create your account
        </div>
        <div className="text-center mt-2">
          {`We've noticed your Twitter is linked to multiple wallets. To create an
          account, please unlink all wallets.`}
        </div>
        <div className="w-full mt-6 p-6 rounded-[20px] bg-dark2 max-h-[30vh] overflow-y-scroll">
          <div className="flex flex-col gap-4 ">
            {bindings.map((item) => {
              return (
                <div
                  key={item.id}
                  className={"text-[15px] font-semibold text-gray2 text-center"}
                >
                  {trimAddr(item.address, 4, 4)}
                </div>
              );
            })}
          </div>
        </div>
        <Button
          className="mt-[70px] !h-[60px] !bg-primary !text-dark1 !text-base !font-bold"
          radius="xl"
          fullWidth
          onClick={unBind}
          loading={processing}
        >
          Unlink all
        </Button>
        <TouchableHighlight
          theme="text"
          className="mt-4 flex items-center"
          display="flex"
          onClick={close}
        >
          <div className="font-semibold text-primary">Cancel</div>
        </TouchableHighlight>
      </div>
    </Modal>
  );
};

export default TwitterUnbindDialog;
