/**
 * request 网络请求工具
 * 更详细的 api 文档: https://github.com/umijs/umi-request
 */
import { extend } from "umi-request";
import store, { actions } from "@/stores";

import {
  isMaintenance,
  maintenanceApiMessage,
  serve_address,
} from "@/config/config";

const codeMessage = {
  200: "服务器成功返回请求的数据。",
  201: "新建或修改数据成功。",
  202: "一个请求已经进入后台排队（异步任务）。",
  204: "删除数据成功。",
  400: "发出的请求有错误，服务器没有进行新建或修改数据的操作。",
  401: "用户没有权限（令牌、用户名、密码错误）。",
  403: "用户得到授权，但是访问是被禁止的。",
  404: "发出的请求针对的是不存在的记录，服务器没有进行操作。",
  406: "请求的格式不可得。",
  410: "请求的资源被永久删除，且不会再得到的。",
  422: "当创建一个对象时，发生一个验证错误。",
  500: "服务器发生错误，请检查服务器。",
  502: "网关错误。",
  503: "服务不可用，服务器暂时过载或维护。",
  504: "网关超时。",
};
/**
 * 异常处理程序
 */

const errorHandler = async (error) => {
  const { response } = error;
  if (response && response.status) {
    let errorText = codeMessage[response.status] || response.statusText;
    if (!errorText) {
      errorText = await response.text();
    }
    // const { status, url } = response;

    //      notification.error({
    //        message: `请求错误 ${status}: ${url}`,
    //        description: errorText,
    //      });
    //    } else if (!response) {
    //      notification.error({
    //        description: '您的网络发生异常，无法连接服务器',
    //        message: '网络异常',
    //      });
  }

  return response;
};
/**
 * 配置request请求时的默认参数
 */

const request = extend({
  errorHandler,
  // 默认错误处理
  credentials: "include", // 默认请求是否带上cookie
  timeout: 10000,
});

request.rpc = async function (method, params = [], headers = {}) {
  if (isMaintenance) {
    throw new Error(maintenanceApiMessage);
  }
  const isNetworkEndpoint = headers.endpoint === "network";
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append(
    "ref",
    typeof window !== "undefined" ? window.location.href : ""
  );
  const session = store.getState().account.session;
  if (session) {
    myHeaders.append("token", session.token);
  }
  const state = store.getState();
  if (state.devtool.config.customAddress && state.features.list.vConsole) {
    myHeaders.append("CustomAddress", state.devtool.config.customAddress);
  }

  Object.keys(headers).forEach((key) => {
    myHeaders.append(key, headers[key]);
  });
  var raw = JSON.stringify({ method: method, params: params });
  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };
  var response = null;

  let url = !state.features.list.vConsole
    ? serve_address
    : state.devtool.config.backend;

  try {
    response = await fetch(url, requestOptions);
    // console.log(response);
    if (response.status !== 200) {
      errorHandler({ response });
      return null;
    }
  } catch (err) {
    throw err;
  }

  var resp = await response.json();
  if ("error" in resp) {
    if (resp.error.message === "Token is not valid. Please login again") {
      store.dispatch(actions.account.logout());
    }
    if ([2002, 2013].includes(resp.error.code)) {
      store.dispatch(actions.account.logout());
    }
    throw resp.error;
  } else if ("result" in resp) {
    return resp.result;
  }
};

export default request;
