import { createGlobalState } from "react-hooks-global-state";
import type { GroupChannel } from "@sendbird/chat/groupChannel";

const initialState: {
  channels: GroupChannel[];
} = { channels: [] };

const { useGlobalState } = createGlobalState(initialState);

export default useGlobalState;
