import { Drawer } from "@mantine/core";
import { FunctionComponent, useCallback, useEffect, useState } from "react";
import { useDisclosure } from "@mantine/hooks";
import { Modal, Button, Checkbox } from "@mantine/core";
import TouchableHighlight from "../common/TouchableHighlight";
import useAccount from "@/hooks/useAccount";
import useUserTwitter from "@/hooks/useUserTwitter";
import useRequest from "@/hooks/useRequest";
import TwitterUnbindDialog from "./TwitterUnbindDialog";
import store, { actions } from "@/stores";
import { TwitterIcon } from "../common/icons";
import UserProfile from "@/components/account/UserProfile";
import { initSpace } from "@/services/chat";
import { toast } from "react-toastify";
import events from "@/services/events";
import { UserInfo } from "@/types/account";
import { getTwitterProfile } from "@/services/twitter";
import { uploadAvatar } from "@/services/image";
import Image from "next/image";
import mixpanel from "mixpanel-browser";
import { updateUserInfo } from "@/services/user";
import AgreeUnlinkTwitterDialog from "./AgreeUnlinkTwitterDialog";
import { bindTwitter } from "@/services/user";
import IconButton from "@/components/common/IconButton";
import { ArrowDownIcon } from "@/components/common/icons";
import { navigateBack } from "@/services/app";
import { AiOutlineInfoCircle } from "react-icons/ai";

async function imageUrlToBase64(url: string): Promise<string> {
  const res = await fetch(url);
  const blob = await res.blob();
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = function () {
      resolve(reader.result as string);
    };
    reader.onerror = function (error) {
      reject(error);
    };
    reader.readAsDataURL(blob);
  });
}

enum Step {
  connectTwitter,
  profile,
}

const UserSetupDialog: FunctionComponent = function () {
  const [opened, { open, close }] = useDisclosure(false);
  const { user, logout } = useAccount();
  const [step, setStep] = useState(Step.connectTwitter);
  const [connectClicked, setConnectClicked] = useState(false);
  const [unbindOpened, { open: openUnbind, close: closeUnbind }] =
    useDisclosure(false);
  const [processing, setProcessing] = useState(false);
  const [twitterSyncing, setTwitterSyncing] = useState(false);
  const [defaultProfile, setDefaultProfile] = useState<any>(null);
  const [tracked, setTracked] = useState(false);
  const [checked, setChecked] = useState(true);
  const [
    agreeDialogOpened,
    { open: openAgreeDialog, close: closeAgreeDialog },
  ] = useDisclosure(false);
  const [binding, setBinding] = useState(false);

  const { authLink, connect, disconnect } = useUserTwitter();

  const onSave = useCallback(async () => {
    if (processing) {
      return;
    }
    try {
      setProcessing(true);
      await initSpace({});
      events.emit("spaceInited", {});
      close();
    } catch (e: any) {
      toast.error(e.message);
    } finally {
      setProcessing(false);
    }
  }, [processing]);

  useEffect(() => {
    async function sync() {
      let resp: any;
      let profile: any;

      try {
        profile = await getTwitterProfile();
        const imageBase64 = await imageUrlToBase64(profile.avatar);
        resp = await uploadAvatar({
          base64: imageBase64.split(",")[1],
          filename: "twitter_avatar",
          uploadType: "user_avatar",
        });
        await updateUserInfo({
          avatar: resp.url,
          name: profile.userName,
          introduction: profile.description,
        });
        await initSpace({});
        events.emit("spaceInited", {});
        close();
      } catch (e: any) {
        toast.error(e.message);
        if (resp && profile) {
          setDefaultProfile({
            avatar: resp.url,
            name: profile.userName,
            introduction: profile.description,
          });
        }

        setStep(Step.profile);
      }
    }
    if (twitterSyncing) {
      sync();
    }
  }, [twitterSyncing]);

  useEffect(() => {
    if (user) {
      setTwitterSyncing(
        !!user?.twitter && !user?.twitterBinding && !user.space
      );
    }
  }, [user]);

  useRequest<UserInfo>(
    user && opened
      ? {
          function: "user/getUserInfo",
          data: {
            userId: user.userId,
          },
        }
      : null,
    {
      refreshInterval: 1000,
      onSuccess(user) {
        store.dispatch(actions.account.setUser(user));
      },
    }
  );

  useEffect(() => {
    if (user && !user.space) {
      open();
    }
  }, [user, open, tracked]);

  useEffect(() => {
    if (
      opened &&
      connectClicked &&
      user?.twitterBinding &&
      checked &&
      !binding
    ) {
      setBinding(true);
      bindTwitter({});
    }
  }, [opened, user, connectClicked, checked, binding]);

  return (
    <>
      <Drawer
        opened={opened}
        position="bottom"
        closeOnClickOutside={false}
        closeOnEscape={false}
        size="xl"
        onClose={close}
        withCloseButton={false}
        classNames={{
          body: "!p-0 h-full",
          content: "!h-full mx-auto w-full sm:!max-w-[450px]",
        }}
      >
        <div className="p-6 h-full pb-10">
          <div className="text-secondary font-semibold">
            <IconButton className="rounded-lg" onClick={logout}>
              <ArrowDownIcon className="rotate-180"></ArrowDownIcon>
            </IconButton>
          </div>
          {step === Step.connectTwitter && (
            <div className="flex flex-col h-full">
              {twitterSyncing ? (
                <div
                  className={`w-full h-full flex items-center justify-center`}
                >
                  <Image
                    src="/assets/loading.gif"
                    width={50}
                    height={50}
                    alt="loading"
                  ></Image>
                </div>
              ) : (
                <>
                  <div className="text-[28px] mt-10 font-bold flex-shrink-0 text-center">
                    Create your profile
                  </div>
                  <div className="flex-1 flex flex-col items-center justify-center mt-[134px]">
                    <div className="w-20 h-20 rounded-full bg-dark1 flex items-center justify-center">
                      <TwitterIcon className="fill-white w-12 h-12"></TwitterIcon>
                    </div>
                    <div className="mt-10 text-sm text-dark1 text-center max-w-[327px]">
                      Connect Twitter to your karat.club profile.
                    </div>
                  </div>
                  <div className="flex-shrink-0 mt-10">
                    {user?.twitterBinding ? (
                      <Button
                        className="mt-6 !h-[60px] !bg-primary !text-dark1 !text-base !font-bold"
                        radius="xl"
                        fullWidth
                        onClick={() => {
                          openUnbind();
                        }}
                      >
                        Connect Twitter
                      </Button>
                    ) : user?.twitter ? (
                      <Button
                        className="mt-6 !h-[60px] !bg-primary !text-dark1 !text-base !font-bold"
                        radius="xl"
                        fullWidth
                        onClick={() => {
                          setStep(Step.profile);
                        }}
                      >
                        Next
                      </Button>
                    ) : (
                      <Button
                        className="mt-6 !h-[60px] !bg-primary !text-dark1 !text-base !font-bold"
                        radius="xl"
                        fullWidth
                        onClick={() => {
                          if (!checked) {
                            openAgreeDialog();
                            return;
                          }
                          setConnectClicked(true);
                          window.open(authLink, "_blank");
                        }}
                      >
                        Connect Twitter
                      </Button>
                    )}
                    {(user?.twitterBinding || !user?.twitter) && (
                      <div className="flex items-center justify-center mt-5 px-5">
                        <AiOutlineInfoCircle className="text-gray1 text-[22px]"></AiOutlineInfoCircle>
                        <div className="text-xs text-gray1 ml-2">
                          {`If you've linked your Twitter account with other
                          accounts in karat.club, they will be unlinked.`}
                        </div>
                      </div>
                    )}
                    {process.env.NEXT_PUBLIC_APP_ENV === "development" && (
                      <div className="flex justify-center mt-6">
                        <TouchableHighlight
                          hoverStyle={{
                            opacity: 0.9,
                            background: "#ffffff",
                          }}
                          onClick={() => {
                            setStep(Step.profile);
                          }}
                        >
                          <span className="text-primary font-bold">
                            Skip it for now
                          </span>
                        </TouchableHighlight>
                      </div>
                    )}
                  </div>
                </>
              )}
            </div>
          )}
          {step === Step.profile && (
            <div className=" h-full flex flex-col">
              <div className="text-[28px] mt-10 font-bold flex-shrink-0">
                Create your profile
              </div>
              <div className="mt-10 ">
                <UserProfile
                  defaultProfile={defaultProfile}
                  onSave={onSave}
                  hideTwitter={true}
                  btnText="Save"
                  required={{
                    twitter: false,
                    avatar: false,
                    name: true,
                    introduction: false,
                  }}
                  btnLoading={processing}
                ></UserProfile>
              </div>
              {(!user?.twitter || user.twitterBinding) && (
                <div className="flex justify-center mt-[18px]">
                  <TouchableHighlight
                    hoverStyle={{
                      opacity: 0.9,
                      background: "#ffffff",
                    }}
                    onClick={() => {
                      setStep(Step.connectTwitter);
                    }}
                  >
                    <span className="text-primary font-bold">Back</span>
                  </TouchableHighlight>
                </div>
              )}
              <div className="h-10 w-full flex-shrink-0"></div>
            </div>
          )}
        </div>
      </Drawer>
      <TwitterUnbindDialog
        twitter={user?.twitterBinding as string}
        opened={unbindOpened}
        close={closeUnbind}
      ></TwitterUnbindDialog>
      <AgreeUnlinkTwitterDialog
        opened={agreeDialogOpened}
        close={closeAgreeDialog}
        onAgree={() => {
          closeAgreeDialog();
          setChecked(true);
        }}
      ></AgreeUnlinkTwitterDialog>
    </>
  );
};

export default UserSetupDialog;
