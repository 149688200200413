import { FunctionComponent, useEffect, useState } from "react";
import {
  ChatIcon,
  UserIcon,
  ExploreIcon,
  StakingIcon,
} from "@/components/common/icons";
import Link from "next/link";
import { routers } from "@/services/router";
import { useRouter } from "next/router";
import { motion } from "framer-motion";
import { Indicator } from "@mantine/core";
import { useTypedSelector } from "@/stores";
import { RiNewspaperLine } from "react-icons/ri";

const AppTabs: FunctionComponent = function () {
  const router = useRouter();
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    const route = Object.values(routers).find((route) => {
      return route.path === router.pathname;
    });
    setVisible(!!route?.isTab);
  }, [router]);

  const hasMessageUnread = useTypedSelector((state) => {
    return state.ui.hasMessageUnread;
  });

  if (!visible) {
    return null;
  }

  return (
    <motion.div
      className={`fixed bottom-0 left-0 flex-shrink-0 w-screen p-4 pb-10 z-30`}
    >
      <div className="sm:max-w-[450px] mx-auto">
        <div className="grid grid-cols-5 bg-tertiary rounded-full h-20 overflow-hidden">
          <div
            className="h-full flex flex-col items-center justify-center bg-transparent"
            aria-label="Spaces"
            onClick={() => {
              router.push(routers.explore.path);
            }}
          >
            <ExploreIcon
              className={`${
                router.pathname === routers.explore.path ? "" : "fill-gray1"
              }`}
            ></ExploreIcon>
            <div
              className={`text-xs font-semibold mt-1 ${
                router.pathname === routers.explore.path ? "" : "text-gray1"
              }`}
            >
              Spaces
            </div>
          </div>
          <div
            className="h-full flex flex-col items-center justify-center bg-transparent"
            aria-label="Message"
            onClick={() => {
              router.push(routers.messages.path);
            }}
          >
            <Indicator
              disabled={!hasMessageUnread}
              inline
              color="red"
              size={8}
              position="bottom-end"
            >
              <ChatIcon
                className={`${
                  router.pathname === routers.messages.path ? "" : "fill-gray1"
                }`}
              ></ChatIcon>
            </Indicator>

            <div
              className={`text-xs font-semibold mt-1 ${
                router.pathname === routers.messages.path ? "" : "text-gray1"
              }`}
            >
              Messages
            </div>
          </div>

          <div
            className="h-full flex flex-col items-center justify-center bg-transparent"
            aria-label="KAT NEWs"
            onClick={() => {
              router.push(routers.news.path);
            }}
          >
            <RiNewspaperLine
              className={`text-[24px] ${
                router.pathname === routers.news.path ? "" : "text-gray1"
              }`}
            ></RiNewspaperLine>
            <div
              className={`text-xs font-semibold mt-1 ${
                router.pathname === routers.news.path ? "" : "text-gray1"
              }`}
            >
              KAT NEWs
            </div>
          </div>

          <div
            className="h-full flex flex-col items-center justify-center bg-transparent"
            aria-label="Staking"
            onClick={() => {
              router.push(routers.staking.path);
            }}
          >
            <StakingIcon
              className={`${
                router.pathname === routers.staking.path ? "" : "fill-gray1"
              }`}
            ></StakingIcon>
            <div
              className={`text-xs font-semibold mt-1 ${
                router.pathname === routers.staking.path ? "" : "text-gray1"
              }`}
            >
              Staking
            </div>
          </div>

          <div
            aria-label="Account"
            className="h-full flex flex-col items-center justify-center"
            onClick={() => {
              router.push(routers.account.path);
            }}
          >
            <UserIcon
              className={`${
                router.pathname === routers.account.path ? "" : "fill-gray1"
              }`}
            ></UserIcon>
            <div
              className={`text-xs font-semibold mt-1 ${
                router.pathname === routers.account.path ? "" : "text-gray1"
              }`}
            >
              Account
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default AppTabs;
