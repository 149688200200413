import { FunctionComponent, useEffect, useState } from "react";
import useAccount from "@/hooks/useAccount";
import { useTypedSelector } from "@/stores";
import { use100vh } from "react-div-100vh";
import { Button } from "@mantine/core";
import { useRouter } from "next/router";
import { needLogin } from "@/services/router";
import { LoginState } from "@/types/store";
import { motion, AnimatePresence } from "framer-motion";
import { useWalletItemActions } from "@dynamic-labs/sdk-react-core";
import { useDynamicContext } from "@dynamic-labs/sdk-react-core";
import TouchableHighlight from "./TouchableHighlight";
import { useDevice } from "@/hooks/useDevice";
import Login from "./Login";

function Loading() {
  const height = use100vh();
  return (
    <div
      className="fixed z-[999] w-full bg-dark1 flex items-center justify-center top-0 left-0"
      style={{
        height: height ? `${height}px` : "100vh",
      }}
    >
      <AnimatePresence>
        <motion.div
          className="text-primary font-bold text-[56px]"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{
            opacity: 0,
          }}
          transition={{
            delay: 0,
            duration: 0.5,
          }}
        >
          Karat.club
        </motion.div>
      </AnimatePresence>
    </div>
  );
}

function Cover() {
  return <Login></Login>;
}

const AppCover: FunctionComponent = function () {
  const [ready, setReady] = useState(false);
  const [skipLogin, setSkipLogin] = useState(false);
  const router = useRouter();

  useEffect(() => {
    setSkipLogin(!needLogin(router.pathname));
  }, [router]);

  const { loginState } = useTypedSelector((state) => {
    return state.account;
  });

  useEffect(() => {
    setTimeout(() => {
      setReady(true);
    }, 2000);
  }, []);

  if (!ready) {
    return <Loading></Loading>;
  }

  if (skipLogin) {
    return null;
  }

  if (loginState === LoginState.Login) {
    return null;
  }

  return <Cover></Cover>;
};

export default AppCover;
