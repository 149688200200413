import { FunctionComponent, useEffect, useState, useRef } from "react";
import { Modal, Button } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import useAccount from "@/hooks/useAccount";
import store from "store2";
import { Carousel } from "react-responsive-carousel";
import TouchableHighlight from "@/components/common/TouchableHighlight";
import { useRouter } from "next/router";
import Router from "next/router";
import "react-responsive-carousel/lib/styles/carousel.min.css";

const tips = [
  {
    img: "/assets/app-welcome-1.png",
    title: "Join and stake early for greater rewards",
    desc: "Early stakes allow higher chance for doubled returns from a larger pool",
  },
  {
    img: "/assets/app-welcome-2.png",
    title: "Earnings are based on three main aspects",
    desc: "The amount you pledge, daily reward ratio, and the withdrawal fee ratio",
  },
  {
    img: "/assets/app-welcome-3.png",
    title: "Withdraw your investment at any time",
    desc: "Withdraw any time or during low space activity to secure higher earnings",
  },
];

const AppWelcomeDialog: FunctionComponent = function () {
  const [opened, { open, close }] = useDisclosure(false);
  const { isLogin, user } = useAccount();
  const [hasOpen, setHasOpen] = useState(
    store.get("app_welcome_dialog") || false
  );
  const [slideIndex, setSlideIndex] = useState(0);
  const lastTipsIdx = tips.length - 1
  const router = useRouter();
  const onNextClick = () => {
    setSlideIndex((prevIndex) => (prevIndex + 1) % tips.length);
  };
  const hasSpace = !!user?.space;
  useEffect(() => {
    if (!hasOpen && isLogin && hasSpace) {
      setTimeout(() => {
        setHasOpen(true);
        open();
        store.set("app_welcome_dialog", true);
      }, 2000);
    }
  }, [hasOpen, isLogin, hasSpace]);

  return (
    <Modal
      fullScreen
      opened={opened}
      centered
      withCloseButton={false}
      onClose={close}
      size={375}
      radius={40}
      zIndex={1000}
      classNames={{
        content: "!bg-dark1 !py-10 !px-6",
        body: "!p-0",
      }}
    >
      <div className="flex flex-col items-center justify-center text-white">
        <div className="">
          <Carousel
            showArrows={false}
            showIndicators={false}
            showStatus={false}
            showThumbs={false}
            selectedItem={slideIndex}
            onChange={setSlideIndex as any}
            emulateTouch
          >
            {tips.map((tip, index) => {
              return (
                <div
                  className="flex flex-col items-center text-center"
                  key={index}
                >
                  <img
                    src={tip.img}
                    style={{
                      width: 200,
                    }}
                  ></img>
                  <div
                    className="text-2xl font-bold max-w-[295px] mt-6"
                    style={{
                      background:
                        "linear-gradient(180deg, #F7744F 0%, #FFC8B9 100%)",
                      backgroundClip: "text",
                      WebkitBackgroundClip: "text",
                      WebkitTextFillColor: "transparent",
                    }}
                  >
                    {tip.title}
                  </div>
                  <div className="mt-4  max-w-[295px]">{tip.desc}</div>
                  <div className="mt-4  max-w-[295px]">
                    <TouchableHighlight
                      theme="text"
                      className="flex items-center"
                      display="flex"
                      onClick={() => {
                        Router.push("/staking/rules");
                      }}
                    >
                      <span className="text-primary text-center text-base font-semibold font-['Inter']">
                        Learn more
                      </span>
                    </TouchableHighlight>
                  </div>
                </div>
              );
            })}
          </Carousel>
        </div>
        {slideIndex !== lastTipsIdx && (
          <div className="absolute bottom-36 flex justify-center items-center gap-2">
            {tips.map((item, index) => {
              const isActive = index === slideIndex;
              return (
                <div
                  className={`w-[10px] h-[10px] rounded-full ${
                    isActive ? "bg-primary" : "bg-[#D9D9D9]"
                  }`}
                  key={index}
                ></div>
              );
            })}
          </div>
        )}
        <div className="absolute bottom-10 w-full max-w-[295px]">
          {slideIndex === lastTipsIdx ? (
            <Button
              className="mt-10 !h-[60px] !bg-primary !text-dark1 !text-base !font-bold"
              radius="xl"
              fullWidth
              onClick={close}
            >
              Close
            </Button>
          ) : (
            <Button
              className="mt-10 !h-[60px] !bg-primary !text-dark1 !text-base !font-bold"
              radius="xl"
              fullWidth
              onClick={onNextClick}
            >
              Next
            </Button>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default AppWelcomeDialog;
