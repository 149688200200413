import { useCallback, useState, useEffect } from "react";
import { useTypedSelector } from "@/stores";
import { getAuthLink, revoke } from "@/services/twitter";
import { toast } from "react-toastify";
import useAccount from "@/hooks/useAccount";
import events from "@/services/events";
import type { UserTwitter } from "@/types/account";

export default function useUserTwitter() {
  const [twitter, setTwitter] = useState<UserTwitter | null>(null);
  const [connecting, setConnecting] = useState(false);
  const [error, setError] = useState("");
  const { login, isLogin } = useAccount();
  const [authLink, setAuthLink] = useState("");
  const [connectClicked, setConnectClicked] = useState(false);

  const { user: userInfo } = useTypedSelector((state) => {
    return state.account;
  });

  useEffect(() => {
    if (userInfo?.twitter) {
      setTwitter(userInfo.twitter);
      setConnecting(false);
      setError("");
    } else {
      setTwitter(null);
      setConnecting(false);
      setError("");
    }
  }, [userInfo?.twitter]);

  useEffect(() => {
    if (connectClicked && userInfo?.twitter) {
      events.emit("twitterConnected", {});
    }
  }, [connectClicked, userInfo?.twitter]);

  useEffect(() => {
    if (userInfo && !userInfo?.twitter && isLogin) {
      getAuthLink().then((res) => {
        setAuthLink(res.authLink);
      });
    }
  }, [isLogin, userInfo?.twitter]);

  useEffect(() => {
    if (connecting) {
      const timer = setTimeout(() => {
        setConnecting(false);
      }, 5000);
      return () => {
        clearTimeout(timer);
      };
    }
  }, [connecting]);

  const connect = useCallback(async () => {
    if (!userInfo) {
      return login();
    }
    if (userInfo.twitter) {
      return;
    }
    setConnectClicked(true);
    setConnecting(true);
  }, [userInfo, login]);

  const disconnect = useCallback(async () => {
    if (!userInfo?.twitter) {
      return;
    }
    await revoke();
    setTwitter(null);
  }, [userInfo]);

  useEffect(() => {
    if (error) {
      toast.error(error);
      setError("");
    }
  }, [error]);

  return {
    twitter,
    connecting,
    error,
    connect,
    authLink,
    disconnect,
  };
}
