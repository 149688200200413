import { FunctionComponent, useCallback, useState } from "react";
import { Modal, Button } from "@mantine/core";
import TouchableHighlight from "../common/TouchableHighlight";
import useRequest from "@/hooks/useRequest";
import { TwitterProfile } from "@/types/api";
import { uploadAvatar } from "@/services/image";
import { toast } from "react-toastify";

async function imageUrlToBase64(url: string): Promise<string> {
  const res = await fetch(url);
  const blob = await res.blob();
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = function () {
      resolve(reader.result as string);
    };
    reader.onerror = function (error) {
      reject(error);
    };
    reader.readAsDataURL(blob);
  });
}

const ImportTwitterProfileDialog: FunctionComponent<{
  opened: boolean;
  close: any;
  onImport: any;
}> = function ({ opened, close, onImport }) {
  const { data: profile } = useRequest<TwitterProfile>({
    function: "twitter/fetchUserProfile",
    data: {},
  });

  const [loading, setLoading] = useState(false);

  const handleImport = useCallback(async () => {
    if (loading || !profile) {
      return;
    }
    try {
      setLoading(true);
      const imageBase64 = await imageUrlToBase64(profile.avatar);
      const resp = await uploadAvatar({
        base64: imageBase64.split(",")[1],
        filename: "twitter_avatar",
        uploadType: "user_avatar",
      });

      close();
      onImport({
        avatar: resp.url,
        userName: profile.userName,
        description: profile.description,
      } as TwitterProfile);
    } catch (e: any) {
      toast.error(e.message);
    } finally {
      setLoading(false);
    }
  }, [loading, profile]);

  return (
    <Modal
      opened={opened}
      centered
      withCloseButton={false}
      onClose={close}
      size={383}
      radius={40}
      zIndex={1000}
      classNames={{
        content: "!bg-dark1 !py-10 !px-6",
        body: "!p-0",
      }}
    >
      <div className="flex flex-col items-center justify-center text-white">
        <div className="font-bold text-[20px] relative flex justify-center w-full">
          <span>Import profile from Twitter</span>
        </div>
        <div className="mt-6 text-center">
          Easily fill out your Karat profile by importing your Twitter info
          picture. It won’t affect your Twitter profile.
        </div>
        <div className="flex items-center w-full mt-6">
          <img
            className="w-[52px] h-[52px] rounded-full mr-4"
            src={profile?.avatar || "/assets/default-user-avatar.svg"}
          ></img>
          <div>
            <div className="text-[15px] text-[#f5f5f5] font-medium flex items-center">
              <div className="w-2 h-2 rounded-full bg-primary mr-2"></div>
              <div>{profile?.userName}</div>
            </div>
            <div className="text-[#A3A3A3] mt-1 text-sm">
              {profile?.description}
            </div>
          </div>
        </div>
        <Button
          className={`mt-6 !h-[60px] !bg-primary !text-dark1 !text-base !font-bold ${
            !profile ? "!bg-tertiary" : ""
          }`}
          radius="xl"
          fullWidth
          disabled={!profile}
          onClick={handleImport}
          loading={loading}
        >
          Import
        </Button>
        <TouchableHighlight
          theme="text"
          className="mt-4 flex items-center"
          display="flex"
          onClick={close}
        >
          <div className="font-semibold text-primary">Skip for now</div>
        </TouchableHighlight>
      </div>
    </Modal>
  );
};

export default ImportTwitterProfileDialog;
