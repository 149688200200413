import { zksyncChainId } from "@/config/config";
import KATContract from "@/chain-info/contracts/KAT.json";
import StakingABI from "@/chain-info/contracts/Staking.json";
import CaptainABI from "@/chain-info/contracts/Captain.json";
import ClaimerRelayerABI from "@/chain-info/contracts/ClaimerRelayer.json";
import ValidatorAirdropABI from "@/chain-info/contracts/ValidatorAirdrop.json";
import VestingABI from "@/chain-info/contracts/Vesting.json";
import AirdropABI from "@/chain-info/contracts/Airdrop.json";
import RewardABI from "@/chain-info/contracts/Reward.json";
import { waitForTransaction } from "wagmi/actions";

export const KATContractInfo = {
  address: process.env.NEXT_PUBLIC_KAT_CONTRACT as any,
  abi: KATContract.abi as any,
  chainId: zksyncChainId,
};

export const stakingContractInfo = {
  address: process.env.NEXT_PUBLIC_STAKING_CONTRACT as any,
  chainId: zksyncChainId,
  abi: StakingABI.abi,
};

export const captainContractInfo = {
  address: process.env.NEXT_PUBLIC_CAPTAIN_CONTRACT as any,
  chainId: zksyncChainId,
  abi: CaptainABI.abi,
};

export const claimerRelayerContractInfo = {
  address: process.env.NEXT_PUBLIC_CLAIMER_RELAYER_CONTRACT as any,
  chainId: zksyncChainId,
  abi: ClaimerRelayerABI.abi,
};

export const validatorAirdropContractInfo = {
  address: process.env.NEXT_PUBLIC_VALIDATOR_AIRDROP_CONTRACT as any,
  chainId: zksyncChainId,
  abi: ValidatorAirdropABI.abi,
};

export const tokenAirdropContractInfo = {
  address: process.env.NEXT_PUBLIC_AIRDROP_CONTRACT as any,
  chainId: zksyncChainId,
  abi: AirdropABI.abi,
};

export const vestingContractInfo = {
  address: process.env.NEXT_PUBLIC_VESTING_CONTRACT as any,
  chainId: zksyncChainId,
  abi: VestingABI.abi,
};

export const rewardContractInfo = {
  address: process.env.NEXT_PUBLIC_REWARD_CONTRACT as any,
  chainId: zksyncChainId,
  abi: RewardABI.abi,
};

export async function waitTransaction({
  hash,
  confirmations,
  chainId,
  timeout,
}: {
  hash: any;
  confirmations: number;
  chainId: number;
  timeout?: number;
}) {
  return new Promise((resolve, reject) => {
    const timer = setTimeout(resolve, timeout || 15000);
    waitForTransaction({
      hash,
      confirmations,
      chainId,
    })
      .then((resp) => {
        clearTimeout(timer);
        resolve(resp);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
