import * as React from "react";
import classnames from "classnames";
import styles from "./style.module.css";
import _ from "lodash";

class TouchableHighlight extends React.Component<any, any> {
  private timer: any;
  constructor(props: any) {
    super(props);
    this.state = {
      touching: false,
    };
    this.onTouchStart = this.onTouchStart.bind(this);
    this.onTouchEnd = this.onTouchEnd.bind(this);
    this.onTouchMove = this.onTouchMove.bind(this);
    this.onClick = this.onClick.bind(this);
  }

  // componentWillUpdate(_: any, nextState: any) {
  //   if (this.state.touching !== nextState.touching) {
  //     this.onChange(nextState);
  //   }
  // }

  onTouchStart() {
    this.setState({
      touching: true,
    });
  }

  onClick() {
    if (this.props.onClick) {
      this.props.onClick();
    }
    this.setState({
      touching: true,
    });
    setTimeout(() => {
      this.setState({
        touching: false,
      });
    }, 200);
  }

  onTouchEnd() {
    if (this.timer) {
      clearTimeout(this.timer);
    }
    this.timer = null;
    setTimeout(() => {
      this.setState({
        touching: false,
        move: false,
      });
    }, 100);
  }

  onTouchMove() {
    if (this.timer) {
      clearTimeout(this.timer);
    }
    this.timer = null;
    this.setState({
      touching: false,
    });
  }

  onChange(state: any) {
    if (this.props.onChange) {
      this.props.onChange(state);
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timer);
  }

  render() {
    const {
      className = "",
      hoverClassName = "hover",
      defaultStyle = {},
      hoverStyle = {},
      theme = "button",
    } = this.props;
    const { touching } = this.state;
    let customStyle = defaultStyle;
    if (theme === "text" && _.isEmpty(hoverStyle)) {
      hoverStyle.backgroundColor = "initial";
      hoverStyle.opacity = "0.8";
    }
    if (touching) {
      customStyle = {
        ...defaultStyle,
        ...hoverStyle,
      };
    }
    return (
      <div
        style={{
          display: this.props.display || "block",
          ...customStyle,
        }}
        className={classnames(className, "transition-all", styles.touchable, {
          [styles.touching]: this.state.touching,
          [hoverClassName]: this.state.touching,
        })}
        onClick={this.onClick}
        onBlur={this.onTouchEnd}
        onTouchMove={this.onTouchMove}
        onTouchStart={this.onTouchStart}
        onTouchCancel={this.onTouchEnd}
        onTouchEnd={this.onTouchEnd}
        onMouseOver={this.onTouchStart}
        onMouseOut={this.onTouchEnd}
      >
        {this.props.children}
      </div>
    );
  }
}

export default TouchableHighlight;
