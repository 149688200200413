import { useCallback, useEffect, useState } from "react";
import { useDynamicContext } from "@dynamic-labs/sdk-react-core";
import { useTypedSelector } from "@/stores";
import { getUserInfo } from "@/services/user";
import store, { actions } from "@/stores";
import events from "@/services/events";
import { LoginState } from "@/types/store";
import { dynamicVerify } from "@/services/user";
import mixpanel from "mixpanel-browser";

export default function useSession() {
  const {
    user: dynamicUser,
    primaryWallet,
    authToken,
    handleLogOut,
  } = useDynamicContext();

  const { session, user, loginState } = useTypedSelector((state) => {
    return state.account;
  });

  const appInited = useTypedSelector((state) => {
    return state.app.inited;
  });

  useEffect(() => {
    if (!appInited) {
      return;
    }
    if (loginState === LoginState.Idle) {
      store.dispatch(actions.account.logout());
    }
  }, [loginState, appInited]);

  useEffect(() => {
    store.dispatch(actions.account.setWallet(primaryWallet));
  }, [primaryWallet]);

  useEffect(() => {
    if (!appInited) {
      return;
    }
    if (loginState === LoginState.Idle && primaryWallet?.connected) {
      store.dispatch(
        actions.account.changeLoginState(LoginState.WallstConnected)
      );
    }
    if (loginState === LoginState.WallstConnected) {
      if (!primaryWallet?.connected) {
        store.dispatch(actions.account.logout());
      } else if (dynamicUser) {
        store.dispatch(
          actions.account.changeLoginState(LoginState.DynamicAuthed)
        );
      }
    }
    if (loginState === LoginState.DynamicAuthed) {
      if (!dynamicUser) {
        store.dispatch(actions.account.logout());
      } else if (session) {
        store.dispatch(
          actions.account.changeLoginState(LoginState.SessionInited)
        );
      }
    }
    if (loginState === LoginState.SessionInited) {
      if (!session) {
        store.dispatch(actions.account.logout());
      } else {
        store.dispatch(actions.account.changeLoginState(LoginState.InitUser));
      }
    }
    if (loginState === LoginState.InitUser) {
      if (!session) {
        store.dispatch(actions.account.logout());
      } else if (user) {
        store.dispatch(actions.account.changeLoginState(LoginState.Login));
        events.emit("loginSuccess", {});
      }
    }
    if (loginState === LoginState.Login) {
      if (!user) {
        store.dispatch(actions.account.logout());
      }
    }
  }, [loginState, primaryWallet, dynamicUser, session, user, appInited]);

  useEffect(() => {
    if (!appInited) {
      return;
    }
    const session = store.getState().account.session;
    if (loginState === LoginState.InitUser && session) {
      getUserInfo({
        userId: session.user_id,
      }).then((user) => {
        store.dispatch(actions.account.setUser(user));
      });
    }
  }, [loginState, appInited]);

  useEffect(() => {
    if (!appInited) {
      return;
    }
    const session = store.getState().account.session;
    if (
      loginState === LoginState.DynamicAuthed &&
      !session &&
      primaryWallet?.address &&
      dynamicUser &&
      authToken
    ) {
      setTimeout(() => {
        if (
          store.getState().account.loginState === LoginState.DynamicAuthed &&
          !store.getState().account.verifying
        ) {
          store.dispatch(actions.account.setVerifying(true));
          dynamicVerify({
            walletAddr: primaryWallet.address,
            jwt: authToken,
            useridx: dynamicUser.userId as string,
          })
            .then((session) => {
              const loginState = store.getState().account.loginState;
              if (loginState === LoginState.DynamicAuthed) {
                store.dispatch(actions.account.setSession(session));
                mixpanel.track("login", {
                  address: session.address,
                  userId: session.user_id,
                  dynamicId: !!session.dynamicId,
                });
              }
            })
            .catch((e: any) => {
              mixpanel.track("dynamic_verify_failed", {
                msg: e.message,
                walletAddr: primaryWallet.address,
                jwt: !!authToken,
                useridx: !!dynamicUser.userId,
              });
              handleLogOut();
            })
            .finally(() => {
              store.dispatch(actions.account.setVerifying(false));
            });
        }
      }, 50);
    }
  }, [
    loginState,
    authToken,
    dynamicUser,
    primaryWallet?.address,
    handleLogOut,
    appInited,
  ]);
}
