import BigNumber from "bignumber.js";
import dayjs from "dayjs";
import {
  zkSync,
  mainnet,
  polygon,
  bsc,
  goerli,
  polygonMumbai,
  zkSyncTestnet,
  sepolia,
} from "wagmi/chains";
import { Token } from "@/types/api";

export const trimAddr = (addr: string, s = 3, e = 3) =>
  addr?.substring(0, s) + "..." + addr?.slice(-e);

export function numberWithCommas(value: number = 0) {
  let v = value.toString();
  const partIndex = v.indexOf(".");
  let part;
  if (partIndex > -1) {
    part = v.slice(partIndex + 1);
    v = v.slice(0, partIndex);
  }
  return v.replace(/\B(?=(\d{3})+(?!\d))/g, ",") + (part ? `.${part}` : "");
}

export const B = 1e9;
export const M = 1e6;
export const K = 1e3;

export const katAmountDesc = (amount?: number, decimal?: number) => {
  let rtn = "";
  if (!amount) {
    rtn = (0).toFixed(decimal);
  } else if (amount >= B) {
    rtn = (amount / B).toFixed(decimal) + "B";
  } else if (amount >= M) {
    rtn = (amount / M).toFixed(decimal) + "M";
  } else {
    rtn = numberWithCommas(parseFloat(amount.toFixed(decimal)));
  }
  if (!decimal) {
    return rtn;
  }
  return rtn.replace(
    (0).toFixed(decimal || 0).slice(-((decimal || 0) + 1)),
    ""
  );
};

export function dateDesc(day: string) {
  const date = dayjs(day);
  const today = dayjs();
  let desc = "";
  if (date.isSame(today, "day")) {
    desc = "Today";
  } else if (date.isSame(today.subtract(1, "day"), "day")) {
    desc = "Yesterday";
  } else if (date.isSame(today, "year")) {
    desc = date.format("MM/DD");
  } else {
    desc = date.format("YYYY/MM/DD");
  }
  return `${desc} ${date.format("ddd")}`;
}

export function chatMessageDate(time: number) {
  const date = dayjs(time);
  const today = dayjs();
  if (date.isSame(today, "day")) {
    return date.format("HH:mm A");
  }
  if (date.isSame(today, "year")) {
    return date.format("MM/DD HH:mm A");
  }
  return date.format("YYYY/MM/DD HH:mm A");
}

export function chatMessageSplitDate(time: number) {
  const date = dayjs(time);
  const today = dayjs();
  if (date.isSame(today, "year")) {
    return date.format("dddd, M/D");
  } else {
    return date.format("dddd, M/D, YYYY");
  }
}

export async function fileToBase64(file: File): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = function () {
      resolve(reader.result as string);
    };
    reader.onerror = function (error) {
      reject(error);
    };
    reader.readAsDataURL(file);
  });
}

export function stakedAmountDisplay(amount: BigNumber) {
  if (amount.isGreaterThan(1e10)) {
    return `${numberWithCommas(amount.div(1e10).toNumber())} million`;
  } else if (amount.isGreaterThan(1e4)) {
    return `${numberWithCommas(amount.div(1e3).toNumber())} thousand`;
  } else {
    return numberWithCommas(amount.toNumber());
  }
}

export const amountDesc = (amount?: number, decimal?: number) => {
  let rtn = "";
  if (!amount) {
    rtn = (0).toFixed(decimal);
  } else if (amount >= B) {
    rtn = (amount / B).toFixed(decimal) + "B";
  } else if (amount >= M) {
    rtn = (amount / M).toFixed(decimal) + "M";
  } else if (amount >= K) {
    rtn = (amount / K).toFixed(decimal) + "K";
  } else {
    rtn = amount.toFixed(decimal);
  }
  if (!decimal) {
    return rtn;
  }
  return rtn.replace(
    (0).toFixed(decimal || 0).slice(-((decimal || 0) + 1)),
    ""
  );
};

export function spaceId(id: number) {
  return id.toString().padStart(3, "0");
}

const AvailableNetworks = [
  goerli,
  mainnet,
  zkSync,
  polygon,
  bsc,
  polygonMumbai,
  zkSyncTestnet,
  sepolia,
];

export function tokenChainName(token: Token) {
  return (
    AvailableNetworks.find((network) => {
      return network.id === token.chainId;
    })?.name || "unknow"
  );
}
