import { FunctionComponent, useCallback } from "react";
import { Modal, Button } from "@mantine/core";
import TouchableHighlight from "../common/TouchableHighlight";
import { useDisclosure } from "@mantine/hooks";

const UserTwitterOptDialog: FunctionComponent<{
  opened: boolean;
  close: any;
  onDisconnect: any;
  onSync: any;
}> = function ({ opened, close, onDisconnect, onSync }) {
  const [
    twitterDisconnectDialogShow,
    { open: openTwitterDisconnectDialog, close: closeTwitterDisconnectDialog },
  ] = useDisclosure(false);
  return (
    <>
      <Modal
        opened={opened}
        centered
        withCloseButton={false}
        onClose={close}
        size={383}
        radius={40}
        zIndex={1000}
        classNames={{
          content: "!bg-dark1 !py-10 !px-6",
          body: "!p-0",
        }}
      >
        <div className="flex flex-col items-center justify-center text-white">
          <Button
            className="mt-6 !h-[60px] !bg-primary !text-dark1 !text-base !font-bold"
            radius="xl"
            fullWidth
            onClick={() => {
              close();
              onSync();
            }}
          >
            Sync profile
          </Button>
          <Button
            className="mt-6 !h-[60px] !bg-tertiary !text-primary !text-base !font-bold"
            radius="xl"
            fullWidth
            onClick={() => {
              close();
              openTwitterDisconnectDialog();
            }}
          >
            Disconnect Twitter
          </Button>
          <TouchableHighlight
            theme="text"
            className="mt-4 flex items-center"
            display="flex"
            onClick={close}
          >
            <div className="font-semibold text-primary">Later</div>
          </TouchableHighlight>
        </div>
      </Modal>
      <Modal
        opened={twitterDisconnectDialogShow}
        centered
        withCloseButton={false}
        onClose={closeTwitterDisconnectDialog}
        size={383}
        radius={40}
        zIndex={1000}
        classNames={{
          content: "!bg-dark1 !py-10 !px-6",
          body: "!p-0",
        }}
      >
        <div className="flex flex-col items-center justify-center text-white">
          <div className="font-bold text-[20px] relative flex justify-center w-full">
            <span>Disconnect Twitter account</span>
          </div>
          <div className="mt-4 text-center">
            Are you sure you want to disconnect your account?
          </div>
          <Button
            className="mt-6 !h-[60px] !bg-primary !text-dark1 !text-base !font-bold"
            radius="xl"
            fullWidth
            onClick={closeTwitterDisconnectDialog}
          >
            Cancel
          </Button>
          <TouchableHighlight
            theme="text"
            className="mt-4 flex items-center"
            display="flex"
            onClick={() => {
              closeTwitterDisconnectDialog();
              onDisconnect();
            }}
          >
            <div className="font-semibold text-primary">Yes, disconnect</div>
          </TouchableHighlight>
        </div>
      </Modal>
    </>
  );
};

export default UserTwitterOptDialog;
