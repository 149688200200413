import {
  ActivatePlanetParams,
  ChatInfo,
  UpdatePlanetParams,
  JoinPlanetParams,
  HostRewardsQuery,
  HostRewardRecord,
  getHostRewardsListQuery,
} from "@/types/api";
import request from "@/utils/request";
import store, { actions } from "@/stores";
import Router from "next/router";

export const goToPlanet = (id: string) => {
  Router.push(`/space/${id}`);
};

export const initSpace = async (data: {}): Promise<any> => {
  return await request.rpc("chat/initSpace", data);
};

export const createChat = async (
  data: ActivatePlanetParams
): Promise<{
  groupId: string;
  shareUrl: string;
}> => {
  return await request.rpc("chat/create", data);
};

export const updateChat = async (
  data: UpdatePlanetParams
): Promise<ChatInfo> => {
  return await request.rpc("chat/updateInfo", data);
};

export const joinChat = async (data: JoinPlanetParams): Promise<any> => {
  return await request.rpc("chat/join", data);
};

export const getHostRewardsList = async (
  data: getHostRewardsListQuery
): Promise<{
  data: HostRewardRecord[];
  page: number;
  size: number;
  next: boolean;
}> => {
  return await request.rpc("chat/getHostRewardsList", data);
};

export const getPlanet = async (data: { id: string }): Promise<ChatInfo> => {
  return await request.rpc("chat/getInfo", data);
};
