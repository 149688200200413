import store, { actions } from "@/stores";

export type FeatureList = {
  vConsole: boolean;
};

export async function enableFeature(name: keyof FeatureList) {
  await store.dispatch(
    actions.features.setFeatures({
      [name]: true,
    })
  );
}

export async function disableFeature(name: keyof FeatureList) {
  await store.dispatch(
    actions.features.setFeatures({
      [name]: false,
    })
  );
}
