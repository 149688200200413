import { FunctionComponent, useCallback } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { useTypedSelector } from "@/stores";
import { hideTopActionSheet } from "@/services/app";
import IconButton from "@/components/common/IconButton";
import { ArrowDownIcon } from "@/components/common/icons";
import events from "@/services/events";

const TopActionSheet: FunctionComponent = function () {
  const topActionSheetsConfig = useTypedSelector((state) => {
    return state.ui.topActionSheets;
  });

  const onClose = useCallback(() => {
    hideTopActionSheet();
  }, []);

  return (
    <AnimatePresence>
      {topActionSheetsConfig && [
        <motion.div
          key="bg"
          className="z-[1000] fixed w-screen  h-screen top-0 left-0 bg-dark1/80"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{
            duration: 0.2,
          }}
          onClick={onClose}
        ></motion.div>,
        <motion.div
          key="sheet"
          className="z-[1001] fixed w-screen top-0 left-0  px-6 pb-8 rounded-b-[40px] bg-dark1"
          initial={{ translateY: "-100%" }}
          animate={{ translateY: "0%" }}
          exit={{ translateY: "-100%" }}
          transition={{
            duration: 0.2,
          }}
        >
          <div className="sm:max-w-[450px] mx-auto w-full bg-dark1">
            <div className="text-white text-center pt-6 font-bold text-xl">
              {topActionSheetsConfig.title}
            </div>
            <div className="mt-6 flex flex-col gap-2">
              {topActionSheetsConfig.sheets.map((sheet) => {
                return (
                  <div
                    key={sheet.id}
                    className="flex items-center justify-between bg-tertiary rounded-full py-2 px-6 pr-2"
                    onClick={() => {
                      events.emit("clickActionSheet", {
                        sheet,
                      });
                    }}
                  >
                    <div className="text-[15px] font-semibold">
                      {sheet.label}
                    </div>
                    <IconButton>
                      <ArrowDownIcon></ArrowDownIcon>
                    </IconButton>
                  </div>
                );
              })}
            </div>
          </div>
        </motion.div>,
      ]}
    </AnimatePresence>
  );
};

export default TopActionSheet;
