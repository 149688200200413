import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { GroupChannel } from "@sendbird/chat/groupChannel";

const slice = createSlice({
  name: "chat",
  initialState: {
    channels: [] as GroupChannel[],
  },
  reducers: {
    setChannels(state, action: PayloadAction<GroupChannel[]>) {
      state.channels = action.payload;
    },
  },
});

export const actions = {
  ...slice.actions,
};

export default slice.reducer;
