import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { QuickNews } from "@/types/news";

const slice = createSlice({
  name: "news",
  initialState: {
    quickNews: [] as QuickNews[],
    quickNewsPagination: {
      page: 1,
      pageSize: 20,
      pageCount: 0,
      total: 0,
    },
  },
  reducers: {
    setQuickNews(state, action: PayloadAction<QuickNews[]>) {
      state.quickNews = action.payload;
    },
    setQuickNewsPagination(
      state,
      action: PayloadAction<{
        page: number;
        pageSize: number;
        pageCount: number;
        total: number;
      }>
    ) {
      state.quickNewsPagination = action.payload;
    },
  },
});

export const actions = {
  ...slice.actions,
};

export default slice.reducer;
