import request from "@/utils/request";
import { UpdateAvatarParams } from "@/types/api";

export const uploadAvatar = async (
  data: UpdateAvatarParams
): Promise<{
  url: string;
}> => {
  return await request.rpc("chat/uploadAvatar", data);
};
