import { FunctionComponent, useCallback, useEffect, useState } from "react";
import IconButton from "@/components/common/IconButton";
import { ArrowDownIcon, TwitterIcon } from "@/components/common/icons";
import useUserTwitter from "@/hooks/useUserTwitter";
import Link from "next/link";
import { ActionIcon } from "@mantine/core";
import UserTwitterOptDialog from "./UserTwitterOptDialog";
import { useDisclosure } from "@mantine/hooks";
import ImportTwitterProfileDialog from "./ImportTwitterProfileDialog";
import TouchableHighlight from "../common/TouchableHighlight";
import useRequest from "@/hooks/useRequest";
import useAccount from "@/hooks/useAccount";
import store, { actions } from "@/stores";
import TwitterUnbindDialog from "./TwitterUnbindDialog";

const UserTwitter: FunctionComponent<{
  onImport: any;
  onDisconnect: any;
  syncMode?: boolean;
}> = function ({ onImport, onDisconnect, syncMode }) {
  const { twitter, authLink, disconnect } = useUserTwitter();
  const [isOptShow, { open: openOpt, close: closeOpt }] = useDisclosure(false);
  const [
    isTwitterDialogShow,
    { open: openTwitterDialog, close: closeTwitterDialog },
  ] = useDisclosure(false);
  const { user } = useAccount();
  const [unbindOpened, { open: openUnbind, close: closeUnbind }] =
    useDisclosure(false);
  const [connectClicked, setConnectClicked] = useState(false);

  useRequest(
    user
      ? {
          function: "user/getUserInfo",
          data: {
            userId: user.userId,
          },
        }
      : null,
    {
      refreshInterval: 1000,
      onSuccess(user) {
        store.dispatch(actions.account.setUser(user));
      },
    }
  );

  useEffect(() => {
    if (connectClicked && user?.twitterBinding) {
      openUnbind();
    }
  }, [user, connectClicked, openUnbind]);

  if (twitter && !user?.twitterBinding) {
    return (
      <>
        <TouchableHighlight
          defaultStyle={{
            borderRadius: "60px",
            background: "#FFEBE5",
          }}
          hoverStyle={{
            background: "#f8d9d0",
          }}
          onClick={() => {
            if (syncMode) {
              openTwitterDialog();
            } else {
              openOpt();
            }
          }}
        >
          <div className="flex items-center justify-between w-full h-[60px] text-[15px]  px-6 pr-2 rounded-full">
            <div className="font-semibold flex items-center">
              {syncMode ? (
                <div>Sync twitter information</div>
              ) : (
                <>
                  <TwitterIcon></TwitterIcon>
                  <div className="ml-4">{twitter.username}</div>
                </>
              )}
            </div>
            <div className="flex items-center">
              <ArrowDownIcon className="fill-primary mr-3"></ArrowDownIcon>
            </div>
          </div>
        </TouchableHighlight>
        <UserTwitterOptDialog
          opened={isOptShow}
          close={closeOpt}
          onDisconnect={() => {
            closeOpt();
            disconnect();
            onDisconnect();
          }}
          onSync={openTwitterDialog}
        ></UserTwitterOptDialog>
        <ImportTwitterProfileDialog
          opened={isTwitterDialogShow}
          close={closeTwitterDialog}
          onImport={onImport}
        ></ImportTwitterProfileDialog>
      </>
    );
  }

  return (
    <>
      <TouchableHighlight
        defaultStyle={{
          borderRadius: "60px",
          background: "#FFEBE5",
        }}
        hoverStyle={{
          background: "#f8d9d0",
        }}
        onClick={() => {
          if (user?.twitterBinding) {
            openUnbind();
          } else {
            setConnectClicked(true);
            window.open(authLink, "_blank");
          }
        }}
      >
        <div className="flex items-center justify-between w-full h-[60px] text-[15px]  px-6 pr-2 rounded-full">
          <div className="font-semibold">Connect Twitter</div>
          <div className="flex items-center">
            <ArrowDownIcon className="fill-primary mr-3"></ArrowDownIcon>
          </div>
        </div>
      </TouchableHighlight>
      <TwitterUnbindDialog
        twitter={user?.twitterBinding as string}
        opened={unbindOpened}
        close={closeUnbind}
      ></TwitterUnbindDialog>
    </>
  );
};

export default UserTwitter;
