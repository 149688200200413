import { FunctionComponent } from "react";

const ToastLoading: FunctionComponent<{
  text: string;
}> = function ({ text }) {
  return (
    <div className="flex items-center">
      <img src="/assets/loading.gif" className="w-7 h-7 mr-0"></img>
      <div>{text}</div>
    </div>
  );
};

export default ToastLoading;
