import { FunctionComponent } from "react";
import { Modal, Button } from "@mantine/core";
import TouchableHighlight from "../common/TouchableHighlight";

const AgreeUnlinkTwitterDialog: FunctionComponent<{
  opened: boolean;
  close: any;
  onAgree: any;
}> = function ({ opened, close, onAgree }) {
  return (
    <Modal
      opened={opened}
      centered
      withCloseButton={false}
      onClose={close}
      size={383}
      radius={40}
      zIndex={1000}
      classNames={{
        content: "!bg-dark1 !py-10 !px-6",
        body: "!p-0",
      }}
    >
      <div className="flex flex-col items-center justify-center text-white">
        <div className="text-center mt-2">
          {`Please agree to 'unlink Twitter from other accounts and link it to the current account' before proceeding to the next step`}
        </div>

        <Button
          className="mt-6 !h-[60px] !bg-primary !text-dark1 !text-base !font-bold"
          radius="xl"
          fullWidth
          onClick={onAgree}
        >
          I agree
        </Button>
        <TouchableHighlight
          theme="text"
          className="mt-4 flex items-center"
          display="flex"
          onClick={close}
        >
          <div className="font-semibold text-primary">Close</div>
        </TouchableHighlight>
      </div>
    </Modal>
  );
};

export default AgreeUnlinkTwitterDialog;
