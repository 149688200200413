import request from "@/utils/request";
import { TwitterProfile } from "@/types/api";

export const getTwitterProfile = async (): Promise<TwitterProfile> => {
  return await request.rpc("twitter/fetchUserProfile", {});
};

export const getAuthLink = async (): Promise<{
  authLink: string;
}> => {
  return await request.rpc("twitter/authlink", {
    isApp: true,
    redirectUrl: `${window.origin}/callback/twitter`,
  });
};

export const verifyFollowing = async (
  account: string
): Promise<{
  result: boolean;
}> => {
  return await request.rpc("twitter/verifyFollowing", {
    account,
  });
};

export const verifyTweetLiked = async (
  likeLink: string
): Promise<{
  result: boolean;
}> => {
  return await request.rpc("twitter/verifyTweetLiked", {
    likeLink,
  });
};

export const verifyTweetRetweeted = async (
  retweetLink: string
): Promise<{
  result: boolean;
}> => {
  return await request.rpc("twitter/verifyTweetRetweeted", {
    retweetLink,
  });
};

export const revoke = async () => {
  return await request.rpc("twitter/revoke", {});
};
