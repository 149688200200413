import Sheet, { SheetRef } from "react-modal-sheet";
import {
  FunctionComponent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import store, { actions, useTypedSelector } from "@/stores";
import { TextInput } from "@mantine/core";
import Image from "next/image";

const PageView: FunctionComponent = function () {
  const ref = useRef<SheetRef>();
  const pageViewConfig = useTypedSelector((state) => {
    return state.ui.pageView;
  });
  const onClose = useCallback(() => {
    store.dispatch(actions.ui.showPageView(null));
  }, []);
  const [fullUrl, setFullUrl] = useState(false);

  const displayUrl = fullUrl
    ? pageViewConfig?.url
    : pageViewConfig?.url
    ? new URL(pageViewConfig?.url).host
    : "";

  return (
    <Sheet
      isOpen={!!pageViewConfig}
      onClose={onClose}
      className="!z-[1000]"
      snapPoints={[0.9]}
      ref={ref}
      disableScrollLocking={true}
    >
      <Sheet.Container>
        <Sheet.Header>
          <div className="flex justify-center  px-4 py-2">
            <div className="bg-gray-200 rounded-full px-4  w-full text-center text-sm">
              <TextInput
                variant="unstyled"
                classNames={{
                  input: "!bg-transparent !w-full !text-center",
                }}
                value={displayUrl}
                onFocus={() => {
                  setFullUrl(true);
                }}
                onBlur={() => {
                  setFullUrl(false);
                }}
              ></TextInput>
            </div>
          </div>
        </Sheet.Header>
        <Sheet.Content disableDrag={true}>
          <Sheet.Scroller>
            <iframe
              className="h-full w-full relative z-10"
              src={pageViewConfig?.url}
            ></iframe>
            <div className="z-0 absolute w-full h-full left-0 top-0 flex items-center justify-center">
              <Image
                src="/assets/loading.gif"
                width={50}
                height={50}
                alt="loading"
              ></Image>
            </div>
          </Sheet.Scroller>
        </Sheet.Content>
      </Sheet.Container>
      <Sheet.Backdrop />
    </Sheet>
  );
};

export default PageView;
